import {DEFAULT_REPORT_PAGE_SIZE} from '@/utils/constants';
import {Table} from 'antd';
import React from 'react';
import columns from './TableTotalOrderColumns';

type Props = {
  table: AgentOrderInterface[];
  metadata: MetadataInterface | undefined;
  page: number;
  onSizeChange: (page: number) => void;
};

const TableTotalOrder: React.FC<Props> = ({
  table,
  metadata,
  page,
  onSizeChange,
}) => {
  return (
    <Table
      className="w-[90vw]"
      rowKey={'agentId'}
      dataSource={table}
      columns={columns(page) as any}
      pagination={{
        current: page + 1,
        total: metadata?.total,
        defaultPageSize: DEFAULT_REPORT_PAGE_SIZE,
        onChange: onSizeChange,
      }}
      scroll={{x: 800}}
    />
  );
};

export default TableTotalOrder;
