import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {
  Login,
  Page404,
  Dashboard,
  Register,
  Accounts,
  Products,
  Campaigns,
  ReportCampaign,
  Profile,
  Orders,
  ReportCampaignAgent,
  Collaborators,
  Documents,
} from '@/containers';
import {ROUTERS} from './utils/constants';
import {Layout} from './components/ProLayout';
import Auth from './components/Auth';
import {ROLES} from './utils/enum';
import ChangePassword from './containers/ChangePassword';
import {Search} from './containers/Search';
import {Payments} from './containers/Payments';
import {BalanceHistory} from './containers/BalanceHistory';
import {ImportedMOFile} from './containers/ImportedMOFile';
import {Posts} from './containers/Posts';
import Notifications from './containers/Notifications';
import {Organizations} from './containers/Organizations';

const MyRoutes = (isSignIn: boolean) => {
  return (
    <BrowserRouter>
      <Routes>
        {!isSignIn ? (
          <Route>
            <Route path="/" element={<Login />} />
          </Route>
        ) : (
          <Route path={'/'} element={<Layout />}>
            <Route path={ROUTERS.Dashboard.path} element={<Dashboard />} />

            <Route path={ROUTERS.Products.path} element={<Products />} />
            <Route path={ROUTERS.Payments.path} element={<Payments />} />
            <Route
              path={ROUTERS.BalanceHistory.path}
              element={<BalanceHistory />}
            />
            <Route path={ROUTERS.Campaigns.path} element={<Campaigns />} />
            <Route path={ROUTERS.Profile.path} element={<Profile />} />
            <Route
              path={ROUTERS.ReportCampaign.path}
              element={<ReportCampaign />}
            />
            <Route
              path={ROUTERS.ReportCampaignAgent.path}
              element={<ReportCampaignAgent />}
            />

            <Route path={ROUTERS.Orders.path} element={<Orders />} />
            <Route path={ROUTERS.Documents.path} element={<Documents />} />
            <Route path={ROUTERS.Search.path} element={<Search />} />
            {/* <Route element={<Auth ignoreRoles={[ROLES.ADMIN]} />}> */}
            <Route
              path={ROUTERS.Notification.path}
              element={<Notifications />}
            />
            {/* </Route> */}
            <Route
              path={ROUTERS.ImportedMOFile.path}
              element={<ImportedMOFile />}
            />
            <Route path={ROUTERS.Posts.path} element={<Posts />} />
            <Route
              path={ROUTERS.Organizations.path}
              element={<Organizations />}
            />

            {/* AUTHORITY ROUTER */}
            <Route element={<Auth ignoreRoles={[ROLES.COLLABORATOR]} />}>
              <Route
                path={ROUTERS.Collaborators.path}
                element={<Collaborators />}
              />
            </Route>

            <Route
              element={
                <Auth ignoreRoles={[ROLES.COLLABORATOR, ROLES.AGENT]} />
              }>
              <Route path={ROUTERS.Accounts.path} element={<Accounts />} />
            </Route>
          </Route>
        )}
        <Route path={ROUTERS.ResetPassword.path} element={<Register />} />
        <Route
          path={ROUTERS.ChangePassword.path}
          element={<ChangePassword />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;
