import EyeIcon from '@/components/EyeIcon';
import {isUserHaveOneOfPermissions} from '@/utils';
import {LOCAL_STORAGE_KEY, ROLES} from '@/utils/enum';
import {formatPrice, isNullOrEmpty} from '@/utils/stringUtils';
import {
  EditOutlined,
  OrderedListOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {ProColumns} from '@ant-design/pro-components';
import {Space, Tag, Tooltip} from 'antd';
import moment from 'moment';

const getColumnsConfig: (
  handleEditProduct: (record: ListProductInterface) => void,
  handleDetailProduct: (record: ListProductInterface) => void,
  actionRef: any,
  hideProduct: (id: number, isHidden: 0 | 1) => void,
  handleCreateLink: (id: number) => void
) => ProColumns<ListProductInterface>[] = (
  handleEditProduct,
  handleDetailProduct,
  actionRef,
  hideProduct,
  handleCreateLink
) => {
  const role = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);

  const convertStatus = (num: number): {name?: string; color?: string} => {
    switch (num) {
      case 0:
        return {name: 'Đang kinh doanh', color: 'success'};
      case 1:
        return {name: 'Ngừng kinh doanh', color: 'error'};
      default:
        return {};
    }
  };

  const columns: ProColumns<ListProductInterface>[] = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 60,
      render: (_, __, indx) => {
        const current = actionRef?.current?.pageInfo?.current || 1;
        const size = actionRef?.current?.pageInfo?.pageSize || 10;
        return (current - 1) * size + indx + 1;
      },
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'supplierName',
      key: 'supplierName',
      align: 'center',
      width: 160,
    },
    // {
    //   title: 'Mã gói',
    //   dataIndex: 'code',
    //   key: 'code',
    //   align: 'center',
    // },
    {
      title: 'Tên gói',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Thời gian sử dụng',
      dataIndex: 'duration',
      key: 'duration',
      align: 'center',
      width: 100,
    },
    {
      title: (
        <div>
          <p>Giá bán</p>
          <p>(Đã bao gồm VAT)</p>
        </div>
      ),
      dataIndex: 'price',
      width: '10%',
      key: 'price',
      align: 'center',
      render: (_, record) => {
        return `${formatPrice(record.price)} VNĐ` || '-';
      },
    },
    {
      title: 'Link sản phẩm',
      dataIndex: 'affiliateLink',
      width: '22%',
      key: 'affiliateLink',
      align: 'center',
      render: (_, record) => {
        if (record?.enableLink) {
          if (isNullOrEmpty(record?.affiliateLink)) {
            return (
              <Tag
                onClick={() => handleCreateLink?.(record?.id)}
                icon={<PlusOutlined />}
                className='cursor-pointer'>
                Tạo link
              </Tag>
            );
          } else {
            return (
              <a
                href={record?.affiliateLink}
                target="_blank"
                rel="noopener noreferrer">
                {record?.affiliateLink}
              </a>
            );
          }
        } else {
          return '--';
        }
      },
    },
    {
      title: 'Hoa hồng',
      dataIndex: 'moneyReceive',
      key: 'moneyReceive',
      align: 'center',
      width: '8%',
      render: (_, record) => {
        return (
          `${formatPrice(
            isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR])
              ? record.moneyReceive
              : record.maxCommissionShare
          )} VNĐ` || '-'
        );
      },
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      align: 'center',
      width: '10%',
      render: (_, record) => {
        return (
          moment(record.modifiedDate).format('DD/MM/YYYY | HH:mm:ss') || '-'
        );
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      width: '10%',
      render: (_, record) => {
        return (
          moment(record.createdDate).format('DD/MM/YYYY | HH:mm:ss') || '-'
        );
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      align: 'center',
    },
    {
      title: 'Trạng thái hoạt động',
      dataIndex: 'status',
      align: 'center',
      render: (_, record) => {
        const obj = convertStatus(record.status);
        return <Tag color={obj.color}>{obj.name}</Tag>;
      },
    },
    {
      title: 'Thao tác',
      dataIndex: 'option',
      align: 'center',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <Space size={10}>
          {!isUserHaveOneOfPermissions([
            ROLES.AM,
            ROLES.AGENT,
            ROLES.COLLABORATOR,
          ]) && (
            <>
              <Tooltip title="Chỉnh sửa sản phẩm">
                <EditOutlined
                  className="text-[#1677ff] cursor-pointer text-[17px] mr-2"
                  onClick={handleEditProduct.bind(null, record)}
                />
              </Tooltip>
              <EyeIcon
                title="Ẩn/Hiện sản phẩm"
                status={!!record.status}
                onClick={hideProduct.bind(
                  null,
                  record.id,
                  record.status ? 0 : 1
                )}
              />
            </>
          )}
          <Tooltip title={'Xem chi tiết sản phẩm'}>
            <OrderedListOutlined
              className="text-[#1677ff] cursor-pointer text-[17px] ml-2"
              onClick={handleDetailProduct.bind(null, record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  if (isUserHaveOneOfPermissions([ROLES.ADMIN, ROLES.AM_LEADER])) {
    return columns.filter(
      column =>
        column.dataIndex !== 'affiliateLink' &&
        column.dataIndex !== 'maxCommissionShare'
    );
  } else if (role === ROLES.AM) {
    return columns.filter(
      column =>
        column.dataIndex !== 'affiliateLink' &&
        column.dataIndex !== 'maxCommissionShare'
    );
  } else if (role === ROLES.AGENT || role === ROLES.COLLABORATOR) {
    return columns.filter(
      column =>
        column.dataIndex !== 'code' &&
        column.dataIndex !== 'createdDate' &&
        column.dataIndex !== 'modifiedBy' &&
        column.dataIndex !== 'status'
    );
  } else {
    return [];
  }
};

export default getColumnsConfig;
