import React from 'react';
import {Button, Result} from 'antd';

export const Page404: React.FC = () => {
  return (
    <div className="App">
      <Result
        status="404"
        title="404"
        subTitle="Xin lỗi, trang bạn truy cập không tồn tại."
        extra={
          <Button
            type="primary"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            Về trang chủ
          </Button>
        }
      />
    </div>
  );
};
