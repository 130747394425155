import {DEFAULT_REPORT_PAGE_SIZE} from '@/utils/constants';
import {formatNumberInSingleLetterNotation} from '@/utils/stringUtils';

export default (page: number) => {
  return [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      render: (_data: any, _report: any, index: number) =>
        page * DEFAULT_REPORT_PAGE_SIZE + index + 1,
    },
    {
      title: 'Đại lý',
      dataIndex: 'agentName',
      key: 'agentName',
    },
    {
      title: 'Doanh thu',
      dataIndex: 'revenue',
      key: 'revenue',
      render: (value: number) => formatNumberInSingleLetterNotation(value),
    },
  ];
};
