import {AxiosResponse} from 'axios';
import {NetWork} from '..';
import {API_URL_AUTH, API_URL_REPORT} from '../apiUrl';
import {getRequestUrl} from '../utils';

const getListProduct = (
  params: DefaultFiltersInterface
): Promise<AxiosResponse<ProductResponseInterface>> => {
  const url = getRequestUrl(API_URL_AUTH.PRODUCT);
  return NetWork.get(url, params);
};

const createProduct = (body: ProductParamsInterface) => {
  const url = getRequestUrl(API_URL_AUTH.PRODUCT);
  return NetWork.post(url, body);
};

const updateProduct = (body: ProductParamsInterface, id: number) => {
  const url = getRequestUrl(API_URL_AUTH.PRODUCT, {
    parentId: id,
  });
  return NetWork.put(url, body);
};

const getCommission = (body: {campaignId: number}) => {
  const url = getRequestUrl(API_URL_AUTH.PRODUCT);
  return NetWork.get(url, body);
};

const listCourse = (): Promise<AxiosResponse<CourseResponseInterface>> => {
  const url = getRequestUrl(API_URL_AUTH.PRODUCT, {
    partial: API_URL_AUTH.COURSE,
  });
  return NetWork.get(url, {
    page: 0,
    size: 100,
  });
};

const listSupplier = (): Promise<AxiosResponse<SuppliersResponseInterface>> => {
  const url = getRequestUrl(API_URL_REPORT.suppliers);
  return NetWork.get(url);
};

const createAffiliatelink = (id: number) => {
  const url = getRequestUrl(API_URL_AUTH.PRODUCT, {
    parentId: id,
    partial: API_URL_AUTH.AFFILIATE,
  });
  return NetWork.get(url);
};

export const productService = {
  getListProduct,
  createProduct,
  updateProduct,
  listCourse,
  listSupplier,
  createAffiliatelink,
  getCommission,
};
