import { PlusOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-components';
import { Button, Popover, Row } from 'antd';

type Props = {
  topupCreateOrganization?: boolean;
  handleOpenModalRegion?: () => void;
  handleOpenModalBranch?: () => void;
  openTopup: (isOpen: boolean) => void;
  handleFilterStatus: (value: string) => void;
};

export const Filters = ({
  topupCreateOrganization,
  handleOpenModalRegion,
  handleOpenModalBranch,
  openTopup, // handleFilterStatus,
}: Props) => {
  return (
    <ProCard className="mb-5" bordered>
      <Row gutter={[24, 12]}>
        {/* <Col xs={24} sm={12} md={5}>
          <Select allowClear className="w-full" placeholder="Nhà cung cấp" />
        </Col>
        <Col xs={24} sm={12} md={5}>
          <Select allowClear className="w-full" placeholder="Chọn tên gói" />
        </Col>
        <Col xs={24} sm={12} md={5}>
          <Select
            options={optionActive}
            allowClear
            className="w-full"
            placeholder="Trạng thái"
            onChange={handleFilterStatus}
          />
        </Col> */}
        <div className="flex flex-1 justify-end">
          <Popover
            open={topupCreateOrganization}
            onOpenChange={openTopup}
            placement="bottomRight"
            arrow={false}
            content={
              <div className="w-[200px]">
                <Button
                  icon={<PlusOutlined />}
                  className="w-full mb-3"
                  onClick={handleOpenModalRegion}>
                  Thêm mới Khu vực
                </Button>
                <Button
                  icon={<PlusOutlined />}
                  className="w-full"
                  onClick={handleOpenModalBranch}>
                  Thêm mới Chi nhánh
                </Button>
              </div>
            }
            trigger="click">
            <Button key="button" type="primary" icon={<PlusOutlined />}>
              Thêm mới tổ chức
            </Button>
          </Popover>
        </div>
      </Row>
    </ProCard>
  );
};
