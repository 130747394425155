import {isUserHaveOneOfPermissions} from '@/utils';
import {STATUS_PAYMENT} from '@/utils/constants';
import {ROLES} from '@/utils/enum';
import {CloseOutlined, PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {ProCard} from '@ant-design/pro-components';
import {
  Button,
  Col,
  DatePicker,
  Popover,
  Row,
  Select,
  Typography,
  message,
} from 'antd';
import './styles.scss';
import {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {paymentServices} from '@/network/services/payments';
import {RequestPayment} from '../ModalRequestPayment';

type Props = {
  onChangeStatus: (status: number) => void;
  onChangeDates: (_: any, value: string[]) => void;
  onCreateChangeStatus?: () => void;
  onExport?: () => void;
  isUser?: boolean;
  callBackUpdate?: () => void;
  isEnablePayment: boolean;
  onRequest: (amount: number) => void;
  topupRequestPayment: boolean;
  openTopupRequestPayment: (isOpen: boolean) => void;
  topupRequestInfor: boolean;
  handleOpenModalUpdateInfor: () => void;
};

export const PaymentsFilter = ({
  onChangeStatus,
  onChangeDates,
  onCreateChangeStatus,
  onExport,
  callBackUpdate,
  isEnablePayment,
  onRequest,
  topupRequestPayment,
  openTopupRequestPayment,
  topupRequestInfor,
  handleOpenModalUpdateInfor,
}: Props) => {
  const [fileObj, setFileObj] = useState<any>();
  const [loadingImport, setLoadingImport] = useState<boolean>(false);

  const onDrop = useCallback((acceptedFiles: any) => {
    if (acceptedFiles[0]) {
      setFileObj(acceptedFiles[0]);
    }
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'text/csv': ['.csv'],
    },
  });

  const onImportData = async () => {
    if (fileObj) {
      setLoadingImport(true);
      const resp = await paymentServices.importData(fileObj);
      const data = resp?.data;
      if (resp?.status === 200) {
        message.success('Import Data thành công');
        setLoadingImport(false);
        setFileObj(null);
        callBackUpdate?.();
      } else {
        message.error(data?.message ?? 'Import Data thất bại');
        setLoadingImport(false);
      }
    } else {
      message.error('Cần chọn file để thao tác');
    }
  };

  return (
    <ProCard
      className="ant-pro-table-search ant-pro-table-search-query-filter"
      bordered>
      <Row
        gutter={[10, 12]}
        className="flex"
        style={{width: '100%', paddingLeft: 10}}>
        <Col style={{minWidth: 250}}>
          <DatePicker.RangePicker
            style={{width: '100%', maxWidth: 500}}
            onChange={onChangeDates}
            format={'YYYY-MM-DD'}
            placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
          />
        </Col>
        <Col style={{flex: 1}}>
          <Select
            allowClear
            style={{minWidth: 200}}
            placeholder="Trạng thái"
            options={STATUS_PAYMENT.map((item: any) => ({
              value: item.id,
              label: item.value,
            }))}
            onChange={onChangeStatus}
          />
        </Col>
        {isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR]) && (
          <>
            {isEnablePayment && (
              <>
                <Popover
                  key="top-up"
                  placement="bottomRight"
                  trigger={'click'}
                  overlayClassName="top-up-popover"
                  content={<RequestPayment onRequest={onRequest} />}
                  open={topupRequestPayment}
                  onOpenChange={openTopupRequestPayment}>
                  <Col className="flex justify-end btnAdd">
                    <Button key="button" icon={<PlusOutlined />} type="primary">
                      Tạo yêu cầu thanh toán
                    </Button>
                  </Col>
                </Popover>
                <Popover
                  key="top-up"
                  placement="bottomRight"
                  trigger={'click'}
                  overlayClassName="top-up-popover"
                  content={
                    <div className="w-[250px]">
                      <Typography>
                        Bạn chưa bổ sung thông tin tài khoản ngân hàng để nhận
                        thanh toán
                      </Typography>
                      <Button
                        key="button"
                        className="mt-3"
                        icon={<PlusOutlined />}
                        onClick={handleOpenModalUpdateInfor}
                        type="primary">
                        Bổ sung thông tin ngân hàng
                      </Button>
                    </div>
                  }
                  open={topupRequestInfor}
                  onOpenChange={openTopupRequestPayment}
                />
              </>
            )}
          </>
        )}
        {isUserHaveOneOfPermissions([ROLES.ADMIN, ROLES.ACT]) && (
          <Row gutter={[0, 12]} className="excelField">
            {!fileObj ? (
              <Col className="accountantBtn" {...getRootProps()}>
                <div>
                  <input {...getInputProps()} />
                  <Button
                    key="button"
                    icon={<PlusOutlined />}
                    onClick={onCreateChangeStatus}
                    type="primary">
                    Chuyển trạng thái
                  </Button>
                </div>
              </Col>
            ) : (
              <Row gutter={[0, 12]} className="accountantBtn excelView">
                <div className="excelContainer">
                  <div {...getRootProps()} className="txtExcelName">
                    {fileObj?.name}
                  </div>
                  <CloseOutlined
                    onClick={() => setFileObj(null)}
                    className="btn-remove-excel-file"
                  />
                </div>
                <Button
                  className="mgl-10"
                  loading={loadingImport}
                  icon={<UploadOutlined />}
                  onClick={onImportData}
                  type="primary">
                  Import
                </Button>
              </Row>
            )}
            <Col>
              <Button
                key="button"
                icon={<PlusOutlined />}
                onClick={onExport}
                type="primary">
                Export
              </Button>
            </Col>
          </Row>
        )}
      </Row>
    </ProCard>
  );
};
