import CkTextEditor from '@/components/CkTextEditor';
import UploadImage from '@/components/UploadImage';
import {RESPONSE_CODE} from '@/network/constants';
import {cmsServices} from '@/network/services/cms';
import {MODAL_POST_STATUS, STATUS_POST} from '@/utils/enum';
import {isNullOrEmpty} from '@/utils/stringUtils';
import {CaretLeftOutlined} from '@ant-design/icons';
import ProForm from '@ant-design/pro-form';
import {Button, Form, Input, Select, message, notification} from 'antd';
import {Fragment, useEffect, useRef, useState} from 'react';
import {CreateTicketModalRef, PreviewPostModal} from '../PreviewModal';
import './styles.scss';

type Props = {
  goBack?: () => void;
  status?: number;
  id: number;
  categories?: CategoryPostInterface[];
  hashtags?: HashtagPostInterface[];
  update?: () => void;
};

type dataProps = {
  loading: boolean;
  detail: PostDetailProps | undefined;
  generatedImageUrl?: string;
};

export const CreatePost = ({
  goBack,
  status,
  id,
  categories,
  hashtags,
  update,
}: Props) => {
  const PreviewPostRef = useRef<CreateTicketModalRef>(null);
  const [data, setData] = useState<dataProps>({
    loading: false,
    detail: undefined,
    generatedImageUrl: undefined,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    return setData({loading: false, detail: undefined});
  }, []);

  const validateFields = async () => {
    await form
      .validateFields()
      ?.then(() => {
        editPost();
      })
      .catch(() => {});
  };

  console.log('data?.detail?.hashtags', data?.detail?.hashtags);

  useEffect(() => {
    form.setFieldsValue({
      categoryId: data?.detail?.categoryId,
      content: data?.detail?.content,
      description: data?.detail?.description,
      enable: data?.detail?.enable,
      hashtags: data?.detail?.hashtags?.map((item: hashtagsProps) => ({
        id: item?.id,
        value: item?.code,
        label: item?.code,
      })),
      icon: data?.detail?.icon,
      name: data?.detail?.name,
      note: data?.detail?.note,
      thumbnail: data?.detail?.thumbnail,
    });
  }, [data?.detail]);

  const getCategoryName = () => {
    const formData = form.getFieldsValue();
    const existItem = categories?.filter(
      (item: CategoryPostInterface) => item?.id === formData?.categoryId
    );
    return existItem?.[0]?.name;
  };

  const getHashtagsId = () => {
    let arr: (number | undefined)[] = [];
    const formData = form.getFieldsValue();
    formData.hashtags?.map((item: string) => {
      const existItem = hashtags?.filter(
        (item2: HashtagPostInterface) => item2?.code === item
      );
      if (!isNullOrEmpty(existItem)) {
        arr.push(existItem?.[0]?.id);
      }
    });

    return arr;
  };

  const editPost = async () => {
    const formData = form.getFieldsValue();
    const params = {
      name: formData?.name,
      description: formData?.description,
      icon: formData?.icon?.[0]?.url ?? formData?.icon,
      thumbnail: formData?.thumbnail?.[0]?.url ?? formData?.thumbnail,
      content: formData?.content,
      position: data?.detail?.position,
      enable: formData?.enable,
      categoryId: formData?.categoryId,
      categoryName: getCategoryName(),
      hashTagIds: getHashtagsId(),
      createdDate: data?.detail?.createdDate,
      modifiedDate: data?.detail?.modifiedDate,
      createdBy: data?.detail?.createdBy,
      modifiedBy: data?.detail?.modifiedBy,
      views: data?.detail?.views,
      note: formData?.note,
    };
    const res =
      status === MODAL_POST_STATUS.EDIT
        ? await cmsServices.editPostDetail(id, params)
        : await cmsServices.createPost(params);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      notification.success({
        message:
          status === MODAL_POST_STATUS.EDIT
            ? 'Chỉnh sửa bài viết thành công'
            : 'Thêm bài viết thành công',
      });
      update?.();
    } else {
      notification.error({
        message: res?.data ?? 'Có lỗi xảy ra, xin vui lòng thử lại!',
      });
    }
  };

  const getPostInfo = async () => {
    const info = await cmsServices.getPostDetail(id);
    if (info?.status === RESPONSE_CODE.SUCCESS) {
      setData((prevState: dataProps) => ({
        ...prevState,
        detail: info?.data,
        loading: false,
      }));
    } else {
      setData((prevState: dataProps) => ({
        ...prevState,
        loading: false,
      }));
      message.error(info?.data ?? 'Có lỗi xảy ra');
    }
    return info;
  };

  const renderFooter = () => {
    if (
      status === MODAL_POST_STATUS.CREATE ||
      (status === MODAL_POST_STATUS.EDIT && !isNullOrEmpty(data?.detail))
    ) {
      return (
        <div
          className="flex w-full mt-10 pb-5"
          style={{justifyContent: 'center'}}>
          <Button
            onClick={() => PreviewPostRef.current?.open(form.getFieldsValue())}
            className="blueBtn">
            Xem trước
          </Button>
          <Button onClick={validateFields} className="blueBtn midBtn">
            {status === MODAL_POST_STATUS.CREATE ? 'Thêm mới' : 'Chỉnh sửa'}
          </Button>
          <Button onClick={goBack}>Hủy bỏ</Button>
        </div>
      );
    }
    return null;
  };

  console.log('generatedImageUrl___', data?.generatedImageUrl);

  return (
    <Fragment>
      <div className="flex fixed h-[80px] bg-[#fbfbfb] z-50 w-4/5 top-10 pt-5 items-center">
        <div onClick={goBack} className="backBtn">
          <CaretLeftOutlined className="backIcon" />
        </div>
        <span className="backTxt">
          {status === MODAL_POST_STATUS.CREATE ? 'Thêm' : 'Chi tiết'} bài viết
        </span>
      </div>
      <ProForm
        form={form}
        layout="vertical"
        submitter={{
          render() {
            return null;
          },
        }}
        request={status === MODAL_POST_STATUS.EDIT ? getPostInfo : undefined}>
        <div className="flex flex-wrap w-full mt-45">
          <Form.Item
            label="Chuyên mục"
            name="categoryId"
            className="categoryView"
            rules={[{required: true, message: 'Vui lòng chọn chuyên mục!'}]}>
            <Select
              placeholder="Chọn chuyên mục"
              options={categories?.map((item: CategoryPostInterface) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Trạng thái"
            name="enable"
            className="statusView"
            rules={[{required: true, message: 'Vui lòng chọn trạng thái!'}]}>
            <Select placeholder="Chọn trạng thái" options={STATUS_POST} />
          </Form.Item>
        </div>
        <Form.Item
          label="Tiêu đề bài viết"
          name="name"
          className="mt-3 w-full nameView"
          rules={[
            {required: true, message: 'Vui lòng thêm tiêu đề bài viết!'},
          ]}>
          <Input placeholder="Thêm tiêu đề bài viết" onChange={() => {}} />
        </Form.Item>
        <div className="flex imageView">
          <UploadImage
            name={'icon'}
            label={'Ảnh icon'}
            required
            url={data?.detail?.icon}
            isCms
          />
          <UploadImage
            name={'thumbnail'}
            label={'Ảnh thumbnail'}
            required
            url={data?.detail?.thumbnail}
            isCms
          />
        </div>
        <Form.Item
          label="Mô tả"
          name="description"
          className="w-full nameView"
          rules={[{required: true, message: 'Vui lòng thêm mô tả!'}]}>
          <Input placeholder="Thêm mô tả" />
        </Form.Item>
        <div className="flex w-full">
          <Form.Item
            label="Nội dung bài viết"
            name="content"
            className="mt-5 w-full nameView"
            rules={[
              {required: true, message: 'Vui lòng thêm nội dung bài viết!'},
            ]}>
            <CkTextEditor
              placeholder={'Nhập nhập nội dung bài viết'}
              data={data?.detail?.content}
              className="flex-1"
            />
          </Form.Item>
          <div
            className={['uploadFile flex items-center justify-start mt-5'].join(
              ' '
            )}>
            <UploadImage
              name={'generatedImage'}
              label={'Tạo ảnh'}
              isCms
              saveUrl={url =>
                setData(prev => ({
                  ...prev,
                  generatedImageUrl: url,
                }))
              }
            />
            {!isNullOrEmpty(data?.generatedImageUrl) && (
              <Button
                className="bg-[#1890ff] text-[white]"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `<img src=${form?.getFieldValue('generatedImage')?.[0]
                      ?.url} width="100%" alt="post_image"/>`
                  );
                  message.success('Copy Html thành công');
                }}>
                Copy Html
              </Button>
            )}
          </div>
        </div>
        <Form.Item
          label="Từ khóa/hashtag"
          name="hashtags"
          className="mt-5 w-full nameView"
          rules={[{required: true, message: 'Vui lòng điền từ khóa/hashtag!'}]}>
          <Select
            placeholder="Chọn từ khóa/hashtag"
            options={hashtags?.map((item: HashtagPostInterface) => ({
              id: item?.id,
              value: item?.code,
              label: item?.code,
            }))}
            mode="multiple"
          />
        </Form.Item>
        <Form.Item label="Ghi chú" name="note" className="mt-5 w-full nameView">
          <Input placeholder="Thêm ghi chú" />
        </Form.Item>
      </ProForm>
      {renderFooter()}
      <PreviewPostModal ref={PreviewPostRef} />
    </Fragment>
  );
};
