export const ROUTERS = {
  Dashboard: {
    key: 'dashboard',
    path: '/',
  },
  Accounts: {
    key: 'accounts',
    path: '/accounts',
  },
  ResetPassword: {
    key: 'register',
    path: '/register',
  },
  ChangePassword: {
    key: 'change-password',
    path: '/change-password',
  },
  Collaborators: {
    key: 'collaborators',
    path: '/collaborators',
  },
  Profile: {
    key: 'profile',
    path: '/profile',
  },
  Products: {
    key: 'products',
    path: '/products',
  },
  Campaigns: {
    key: 'campaigns',
    path: '/campaigns',
  },
  ReportCampaign: {
    key: 'report-campaign',
    path: '/report-campaign',
  },
  ReportCampaignAgent: {
    key: 'report-campaign-agent',
    path: '/report-campaign-agent',
  },
  Orders: {
    key: 'orders',
    path: '/orders',
  },
  Documents: {
    key: 'documents',
    path: '/documents',
  },
  Search: {
    key: 'search',
    path: '/search',
  },
  Payments: {
    key: 'payments',
    path: '/payments',
  },
  BalanceHistory: {
    key: 'balance-history',
    path: '/balance-history',
  },
  ImportedMOFile: {
    key: 'imported-mo-file',
    path: '/imported-mo-file',
  },
  Posts: {
    key: 'posts',
    path: '/posts',
  },
  Notification: {
    key: 'notification',
    path: '/notification',
  },
  Organizations: {
    key: 'organizations',
    path: '/organizations',
  },
};

export const DEFAULT_PAGE_SIZE = 50;

export const DEFAULT_REPORT_PAGE_SIZE = 10;

export const DEFAULT_FORMAT_DATE = 'YYYY-MM-DD HH:mm:ss';

export const DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE = 768;

export const PAGE_SIZE_LIST = [10, 20, 50, 100, 200];

export const GLOBAL_HEIGHT = 64 + 56 + 48;

export const DEFAULT_PASSWORD = '1234567a@';

export const dateFormat = 'DD/MM/YYYY';

export const DATE_FORMAT = 'DD/MM/YYYY';

export const STATUS_ORDER = [
  {id: 0, value: 'Đã tạo đơn hàng'},
  {id: 1, value: 'Đang vận chuyển'},
  {id: 2, value: 'Hoàn thành'},
  {id: 3, value: 'Thất bại'},
];

export const STATUS_PAYMENT = [
  {id: 0, value: 'Chờ xử lý'},
  {id: 1, value: 'Đang xử lý'},
  {id: 2, value: 'Đã chuyển khoản'},
  {id: 3, value: 'Đã nhận tiền'},
  {id: 4, value: 'Đã huỷ'},
];

export const MO_STATUS = [
  {id: 0, value: 'Chờ xử lý'},
  {id: 1, value: 'Đang xử lý'},
  {id: 2, value: 'Thành công'},
  {id: 3, value: 'Thất bại'},
];

export const MO_ACTION = [
  {id: 'import', value: 'import'},
  {id: 'export', value: 'export'},
];

export enum CUSTOMER_CARE_STATUS {
  NEW = 0,
  CONTACTING = 1,
  CONFIRMED = 2,
  CANCELLED = 3,
}

export const ruleValidatePassword = {
  pattern: new RegExp(/(?=.*[0-9])(?=.*[!@#$%^&*])^.*$/),
  required: true,
  message:
    'Mật khẩu cần ít nhất 8 ký tự, trong đó có ít nhất 1 chữ số và 1 ký tự đặc biệt',
};

export const LIST_ROLES = [
  {
    name: 'AM Leader',
    code: 'AM_LEADER',
  },
  {
    name: 'AM',
    code: 'AM',
  },
  {
    name: 'Accountant',
    code: 'ACCOUNTANT',
  },
];

export const optionActive = [
  {label: 'Đang hoạt động', value: 0},
  {label: 'Ngừng hoạt động', value: 1},
];

export const LIST_POSITION = [
  {
    name: 'Công ty khu vực',
    code: 'REGION_COMPANY',
  },
  {
    name: 'Chi nhánh công ty',
    code: 'REGION_COMPANY_BRANCH',
  },
];
