import UploadImage from '@/components/UploadImage';
import {APPLICATION_ACTION_TYPE} from '@/context/action';
import AppContext from '@/context/appContext';
import {RESPONSE_CODE} from '@/network/constants';
import accountsServices, {getInfoUser} from '@/network/services/accounts';
import {productService} from '@/network/services/products';
import {CATEGORY_TYPE_IMAGE} from '@/utils/enum';
import {getListBank, validateCollaboratorCommissionFee} from '@/utils/helper';
import {formatPrice} from '@/utils/stringUtils';
import {
  DrawerForm,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components';
import {Form, Input, Space, Typography, message} from 'antd';
import {
  Ref,
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';

type Props = {
  isNeedUpdate?: boolean;
  onReload?: () => void;
};

type FormCreateInterface = {
  userName: string;
  bankAccountName: string;
  fullname: string;
  managerId: number;
  status: number;
  phoneNumber: string;
  agentCode?: string;
  identityCode: string;
  bankAccount: string;
  bankName: string;
  imageBankAccount: any;
  imageIdentityFront: any;
  imageIdentityBack: any;
  externalCode: string;
  agentSupplierCommissionFees:
    | {
        supplierId: number | undefined;
        supplierName: string | undefined;
        commissionFee: string | undefined;
      }[]
    | undefined;
  collaboratorSupplierCommissionFees:
    | {
        supplierId: number | undefined;
        supplierName: string | undefined;
        commissionFee: string | undefined;
      }[]
    | undefined;
};

export type CreateCollaboratorModalRef = {
  onOpenModal: (isEdit?: boolean, data?: AccountType) => void;
};

const CreateCollaborator = (
  props: Props,
  ref: Ref<CreateCollaboratorModalRef>
) => {
  const {isNeedUpdate, onReload} = props;
  const [form] = Form.useForm<FormCreateInterface>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {dispatch} = useContext(AppContext);

  const onOpenModal = () => {
    setIsOpen(true);
    if (!isNeedUpdate) getListSupplier();
  };

  const getListSupplier = async () => {
    const res = await productService.listSupplier();
    const {data} = res?.data;
    if (res.status === RESPONSE_CODE.SUCCESS) {
      form.setFieldsValue({
        collaboratorSupplierCommissionFees: data.map((item: any) => ({
          supplierId: item.id,
          supplierName: item.name,
          commissionFee: formatPrice(Number(item.collaboratorCommissionFee)),
        })),
      });
    }
  };

  useImperativeHandle(ref, () => ({
    onOpenModal,
  }));

  const onCancel = (needReset: boolean = false) => {
    setIsOpen(false);
    if (needReset) {
      form.resetFields();
      onReload?.();
    }
  };

  const onOpenChange = (visible: boolean) => {
    if (!visible) {
      onCancel();
    }
  };

  const getInfo = async () => {
    const info = await getInfoUser();
    if (info) {
      dispatch({
        type: APPLICATION_ACTION_TYPE.UPDATE_USER_INFOR,
        payload: info,
      });
    }
  };

  const onFinish = async (values: FormCreateInterface) => {
    const data: any = {
      ...values,
      imageIdentityFront: values.imageIdentityFront?.[0]?.url,
      imageIdentityBack: values.imageIdentityBack?.[0]?.url,
      externalCode: values?.externalCode,
      collaboratorSupplierCommissionFees:
        values?.collaboratorSupplierCommissionFees,
    };
    let response;
    response = isNeedUpdate
      ? await accountsServices.updateCollaborators(data)
      : await accountsServices.createCollaborators(data);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      getInfo();
      onCancel(true);
      message.success(
        isNeedUpdate
          ? 'Cập nhật tài khoản thành công'
          : 'Thêm tài khoản thành công'
      );
      setNeedUpdateCollab();
      setIsOpen(false);
    } else {
      message.error(response?.data ?? 'Có lỗi xảy ra');
    }
  };

  const setNeedUpdateCollab = () => {
    localStorage.setItem(
      APPLICATION_ACTION_TYPE.NEED_UPDATE_COLLAB_INFO,
      'false'
    );
  };

  return (
    <DrawerForm
      title={isNeedUpdate ? 'Cập nhật tài khoản CTV' : 'Thêm tài khoản CTV'}
      size="middle"
      form={form}
      open={isOpen}
      onOpenChange={onOpenChange}
      drawerProps={{
        destroyOnClose: true,
        onClose: () => {
          setIsOpen(false);
          setNeedUpdateCollab();
        },
        maskClosable: false,
      }}
      grid
      width={window.innerWidth > 900 ? 500 : window.innerWidth - 100}
      rowProps={{gutter: 10}}
      submitTimeout={2000}
      submitter={{
        searchConfig: {
          submitText: isNeedUpdate ? 'Cập nhật' : 'Thêm',
        },
      }}
      onFinish={onFinish}>
      <ProFormText
        placeholder={'Nhập họ và tên'}
        name="fullname"
        label="Họ và tên"
      />
      {!isNeedUpdate && (
        <ProFormText
          placeholder={'Nhập số điện thoại'}
          name="phoneNumber"
          required
          rules={[{required: true}]}
          label="Số điện thoại"
        />
      )}

      <ProFormText
        name="identityCode"
        label="Số CCCD"
        fieldProps={{
          maxLength: 12,
        }}
        placeholder={'Nhập số căn cước công dân'}
      />
      <Space
        style={{
          justifyContent: 'space-evenly',
          display: 'flex',
          width: window.innerWidth > 900 ? 500 : window.innerWidth - 100,
        }}>
        <UploadImage
          label="Ảnh mặt trước"
          url={undefined}
          name="imageIdentityFront"
          category={CATEGORY_TYPE_IMAGE.IDENTITY}
        />
        <UploadImage
          label="Ảnh mặt sau"
          name="imageIdentityBack"
          url={undefined}
          category={CATEGORY_TYPE_IMAGE.IDENTITY}
        />
      </Space>
      <ProFormText
        name="bankAccount"
        label="Số TK ngân hàng"
        placeholder={'Nhập số tài khoản ngân hàng'}
      />
      <ProFormText
        placeholder={'Nhập tên chủ tài khoản'}
        name="bankAccountName"
        label="Tên chủ tài khoản"
      />
      <ProFormSelect
        request={getListBank}
        name="bankName"
        showSearch
        label="Tên ngân hàng"
      />

      {!isNeedUpdate && (
        <ProFormText
          label="Mã MO:"
          name="externalCode"
          placeholder={'Nhập mã MO'}
        />
      )}
      {!isNeedUpdate && (
        <div className="flex flex-col w-[calc(100%)]">
          <Typography className="mb-2">
            Tỉ lệ hoa hồng với mỗi nhà cung cấp:
          </Typography>
          <Form.List name="collaboratorSupplierCommissionFees">
            {fields => (
              <>
                {fields.map(({key, name, ...restField}) => (
                  <div
                    key={'item' + key}
                    className="flex justify-between ml-2 mr-2">
                    <Form.Item
                      {...restField}
                      name={[name, 'supplierName']}
                      className="w-[calc(30%)]">
                      <Input placeholder="Hoa hồng..." disabled />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'commissionFee']}
                      rules={[
                        {
                          validator: validateCollaboratorCommissionFee,
                          required: true,
                        },
                      ]}
                      className="w-[calc(50%)] ml-2">
                      <Input placeholder="Hoa hồng..." />
                    </Form.Item>
                    <Form.Item className="flex">
                      <Typography className="ml-5">%</Typography>
                    </Form.Item>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </div>
      )}
    </DrawerForm>
  );
};

export const CreateCollaboratorModal = forwardRef(CreateCollaborator);
