import {AxiosResponse} from 'axios';
import {getRequestUrl} from '../utils';
import {API_URL_ORDER, IMPORT_EXPORT} from '../apiUrl';
import {NetWork} from '..';

const get = (
  params: FilterOrderParams
): Promise<AxiosResponse<OrderResponseInterface>> => {
  const url = getRequestUrl(API_URL_ORDER.ORDER, {
    partial: API_URL_ORDER.LIST,
  });
  return NetWork.get(url, params);
};

const getDetail = (id: number): Promise<AxiosResponse<OrderItemInterface>> => {
  const url = getRequestUrl(API_URL_ORDER.ORDER, {
    partial: `${API_URL_ORDER.DETAILS}/${id}`,
  });
  return NetWork.get(url);
};

const importMOCode = (file: any): Promise<AxiosResponse<any>> => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  const url = getRequestUrl(
    IMPORT_EXPORT.BASE,
    {
      parentId: IMPORT_EXPORT.IMPORT,
    },
    {type: 'import_mo'}
  );
  return NetWork.post(url, bodyFormData, {
    headers: {
      'Content-Disposition': 'attachment',
      'Content-Type': 'application/vnd.ms-excel',
    },
  });
};

export const ordersServices = {
  get,
  getDetail,
  importMOCode,
};
