import {ProTable} from '@ant-design/pro-components';
import React from 'react';
import {OrdersFilter} from './OrdersFilter';
import {useOrders} from '@/hooks/useOrders';
import {PAGE_SIZE_LIST} from '@/utils/constants';
import {OderDetailModal} from './OrdersDetails';
import getColumnsConfig from './TableColumns';
import {useWindowSize} from '@/hooks/useWindowSize';
import {formatPrice} from '@/utils/stringUtils';
import {isRoleCTV} from '@/utils';

type Props = {
  isAgentSold: boolean;
};
export const OrderTab: React.FC<Props> = ({isAgentSold}) => {
  const {
    state,
    actionRef,
    orderDetailModalRef,
    handleSearch,
    onChangeDate,
    onChangeStatus,
    openDetailModal,
    onPageChange,
  } = useOrders(isAgentSold);

  const columns = getColumnsConfig(openDetailModal, actionRef, isAgentSold);
  const windowSize = useWindowSize();

  const props: any = {};
  if (isRoleCTV()) {
    props.footer = () => {
      return (
        <div className="flex justify-end mr-5">
          <p>
            <span className="font-bold">Doanh thu:</span>{' '}
            {formatPrice(state.totalRevenue)} VNĐ
          </p>
          <p className="ml-2">
            <span className="font-bold">Hoa hồng:</span>{' '}
            {formatPrice(state.totalCommission)} VNĐ
          </p>
        </div>
      );
    };
  }

  return (
    <div className="flex flex-col">
      <ProTable<any>
        {...props}
        scroll={{x: 1700, y: windowSize?.height - 400}}
        className={'custom-table'}
        rowKey="id"
        loading={state?.loading}
        dataSource={state.orders}
        onRow={record => {
          return {
            onClick: () => openDetailModal(record.id),
          };
        }}
        columns={columns}
        actionRef={actionRef}
        cardBordered
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        search={{
          collapseRender: false,
          labelWidth: 'auto',
        }}
        searchFormRender={() => (
          <OrdersFilter
            onChange={handleSearch}
            onChangeDate={onChangeDate}
            onChangeStatus={onChangeStatus}
          />
        )}
        options={false}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: state?.filters.page + 1,
          total: state?.metadata?.total,
          defaultPageSize: state?.filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: total => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
        dateFormatter="string"
      />
      <OderDetailModal ref={orderDetailModalRef} />
    </div>
  );
};
