import {EditOutlined, EyeOutlined} from '@ant-design/icons';
import {ProColumns} from '@ant-design/pro-components';
import {Space, Tooltip} from 'antd';
import Link from 'antd/es/typography/Link';
import {RefObject} from 'react';
import {CreateDocumentModalRef} from '../CreateDocumentModal';
import {ModalDetailDocumentRef} from '../ModalDetail';

const customLink = (link: string) =>
  link ? (
    <Link href={link} target="_blank" className="one-line-text">
      {link}
    </Link>
  ) : (
    '--'
  );

export default (
  page: number,
  size: number,
  createModal: RefObject<CreateDocumentModalRef>,
  openModalDetailRef: RefObject<ModalDetailDocumentRef>
): ProColumns<DocumentItemInterface>[] => [
  {
    title: 'STT',
    dataIndex: 'index',
    key: 'index',
    align: 'center',
    width: 50,
    render: (_, _report, index) => page * size + index + 1,
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    width: 120,
  },
  {
    title: 'Tên sản phẩm',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'Ảnh',
    dataIndex: 'image',
    key: 'image',
    align: 'center',
    render: (_, record) => (
      <div className="max-h-32 overflow-hidden overflow-ellipsis whitespace-nowrap">
        {customLink(record.image)}
      </div>
    ),
  },
  {
    title: 'Video',
    dataIndex: 'video',
    key: 'video',
    align: 'center',
    render: (_, record) => (
      <div className="max-h-32 overflow-hidden overflow-ellipsis whitespace-nowrap">
        {customLink(record.video)}
      </div>
    ),
  },
  {
    title: 'Đường dẫn tham khảo',
    dataIndex: 'referenceLink',
    key: 'referenceLink',
    align: 'center',
    width: 300,
    render: (_, record) => (
      <div className="max-h-32 overflow-hidden overflow-ellipsis whitespace-nowrap">
        {customLink(record.referenceLink)}
      </div>
    ),
  },
  {
    title: 'Thao tác',
    align: 'center',

    width: 100,
    render: (_, record) => {
      return (
        <Space size={10}>
          <Tooltip title={'Chỉnh sửa'}>
            <EditOutlined
              onClick={() => {
                createModal.current?.open(true, record);
              }}
              className="text-[#1677ff] cursor-pointer text-[17px] mr-2"
            />
          </Tooltip>
          <Tooltip title={'Xem chi tiết'}>
            <EyeOutlined
              onClick={() => {
                openModalDetailRef.current?.openModal(true, record);
              }}
              className="text-[#1677ff] cursor-pointer text-[17px]"
            />
          </Tooltip>
        </Space>
      );
    },
  },
];
