import {IS_HIDE_AMOUNT} from '@/utils/enum';

export const initialState: AppStorage = {
  userInfor: {
    avatar: '',
    bankAccount: '',
    bankAccountName: '',
    bankBranch: '',
    bankName: '',
    fullname: '',
    identityCode: '',
    imageBankAccount: '',
    imageIdentityBack: '',
    imageIdentityFront: '',
    isFullInfo: false,
    managerId: -1,
    phoneNumber: '',
    role: '',
    status: -1,
    userId: -1,
    userName: '',
    balance: 0,
    balanceAvailable: 0,
  },
  isHideAmount: localStorage.getItem(IS_HIDE_AMOUNT) === 'true' ? true : false,
};
