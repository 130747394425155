import {FC} from 'react';
import {ProTable} from '@ant-design/pro-components';

import {useDocuments} from '@/hooks/useDocuments';
import {useWindowSize} from '@/hooks/useWindowSize';
import {PAGE_SIZE_LIST} from '@/utils/constants';

import {DocumentFilter} from './DocumentFilter';
import {CreateDocumentModal} from './CreateDocumentModal';
import columns from './Columns';
import {ModalDetailDocument} from './ModalDetail';

export const Documents: FC = () => {
  const {
    documents,
    metadata,
    filters,
    loading,
    actionRef,
    createModalRef,
    modalDetailRef,
    onCreateDocument,
    onPageChange,
    onChangeText,
    onReload,
    onSearch,
  } = useDocuments();

  const windowSize = useWindowSize();

  return (
    <div>
      <ProTable<DocumentItemInterface>
        className="custom-table"
        rowKey="id"
        loading={loading}
        dataSource={documents}
        scroll={{x: 1000, y: windowSize?.height - 400}}
        columns={columns(
          filters.page,
          filters.size,
          createModalRef,
          modalDetailRef
        )}
        actionRef={actionRef}
        cardBordered
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        search={{
          collapseRender: false,
          labelWidth: 'auto',
        }}
        searchFormRender={() => (
          <DocumentFilter
            onChangeText={onChangeText}
            onSearch={onSearch}
            onCreateDocument={onCreateDocument}
          />
        )}
        options={false}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: filters.page + 1,
          total: metadata?.total,
          defaultPageSize: filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: total => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
        dateFormatter="string"
      />
      <CreateDocumentModal ref={createModalRef} onReload={onReload} />
      <ModalDetailDocument ref={modalDetailRef} />
    </div>
  );
};
