import {AxiosResponse} from 'axios';
import {getRequestUrl} from '../utils';
import {API_SEARCH, API_URL_REPORT} from '../apiUrl';
import {NetWork} from '..';

const getSearch = (
  params: SearchParams
): Promise<AxiosResponse<SearchResponseInterface>> => {
  const url = getRequestUrl(API_SEARCH.SEARCH);
  return NetWork.get(url, params);
};

const getListCampaign = () => {
  const url = getRequestUrl(API_SEARCH.REGISTER_PACK, {
    parentId: API_SEARCH.GET_CAMPAIGN,
  });
  return NetWork.get(url);
};

const createRequestOTP = (
  msisdn: string,
  packageCode: string,
  campaignId?: number
): Promise<AxiosResponse<ResponseOTPInterface>> => {
  const subId = campaignId !== undefined ? `&campaignId=${campaignId}` : '';
  const url = getRequestUrl(API_SEARCH.REGISTER_PACK, {
    parentId: API_SEARCH.CUSTOMER,
    partial: msisdn,
    subId: `${API_SEARCH.CREATE_REQUEST}?packageCode=${packageCode}${subId}`,
  });
  return NetWork.post(url);
};

const confirmRegister = (orderCode: string, otp?: string) => {
  const url = getRequestUrl(API_SEARCH.REGISTER_PACK, {
    parentId: `${API_SEARCH.REGISTER}?orderCode=${orderCode}&otp=${otp}`,
  });
  return NetWork.post(url);
};

const suppliers = (): Promise<AxiosResponse<SuppliersResponseInterface>> => {
  const url = getRequestUrl(
    API_URL_REPORT.suppliers,
    {},
    {
      type: 'data_pack',
    }
  );
  return NetWork.get(url);
};

export const searchServices = {
  getSearch,
  getListCampaign,
  createRequestOTP,
  confirmRegister,
  suppliers,
};
