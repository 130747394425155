import {AxiosResponse} from 'axios';
import {API_URL_DOCUMENTS} from '../apiUrl';
import {NetWork} from '..';
import {deleteParamsNotUsing} from '@/utils/stringUtils';
import {getRequestUrl} from '../utils';

export const get = (
  params: DocumentParamsInterface
): Promise<AxiosResponse<DocumentResponseInterface>> => {
  deleteParamsNotUsing(params);
  const url = getRequestUrl(API_URL_DOCUMENTS.documents);
  return NetWork.get(url, params);
};

export const create = (
  body: DocumentBodyInterface
): Promise<AxiosResponse<DocumentResponseInterface>> => {
  const url = getRequestUrl(API_URL_DOCUMENTS.documents);
  return NetWork.post(url, body);
};

export const detail = (
  id: number
): Promise<AxiosResponse<DocumentResponseInterface>> => {
  const url = getRequestUrl(API_URL_DOCUMENTS.documents, {parentId: id});
  return NetWork.get(url);
};

export const update = (
  id: number,
  body: DocumentBodyInterface
): Promise<AxiosResponse<DocumentResponseInterface>> => {
  const url = getRequestUrl(API_URL_DOCUMENTS.documents, {parentId: id});
  return NetWork.put(url, body);
};

export const documentServices = {
  get,
  create,
  detail,
  update,
};
