import {TARGET_TYPES} from '@/utils/enum';
import {ProColumns} from '@ant-design/pro-components';
import {Tag} from 'antd';

const getColumnsConfig = (page: number, size: number) => {
  const columns: ProColumns<any>[] = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 60,
      render: (_, __, indx) => {
        return page * size + indx + 1;
      },
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: 'Thời gian gửi',
      dataIndex: 'sendTime',
      key: 'sendTime',
      align: 'center',
    },
    {
      title: 'Gửi cho',
      dataIndex: 'targetType',
      key: 'targetType',
      align: 'center',
      render: (_, record) => {
        return TARGET_TYPES.find(item => item.value === record.targetType)
          ?.label;
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (_, record) => {
        if (record.status) {
          return <Tag color={'green'}>Đã gửi</Tag>;
        }
        return <Tag color={'orange'}>Chưa gửi</Tag>;
      },
    },
  ];
  return columns;
};

export default getColumnsConfig;
