import {PROVIDED_TYPE} from '@/utils/enum';
import {DrawerForm} from '@ant-design/pro-form';
import {Form, Input, Select} from 'antd';
import React, {Ref, forwardRef, useImperativeHandle} from 'react';
import {LayoutGeneral} from '../LayoutGeneralInfor';
import {LayoutDetails} from '../LayoutDetails';
import {formatPrice} from '@/utils/stringUtils';

export type DetailModalRef = {
  open: (data?: ProductItemInterface | undefined) => void;
};

type Props = {
  listCourse: Course[];
  suppliers: SuppliersInterface[];
};

const ModalDetail = (
  {listCourse, suppliers}: Props,
  ref: Ref<DetailModalRef>
) => {
  const [form] = Form.useForm();
  const [open, setOpen] = React.useState<boolean>(false);
  const [dataDetails, setDataDetails] = React.useState<
    ProductItemInterface | undefined
  >();
  const [supplier, setSupplier] = React.useState<{
    supplierId?: number;
    supplierCode?: string;
    productType?: string;
  }>();

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    handleFillData(dataDetails);
  }, [dataDetails]);

  const handleFillData = (data: ProductItemInterface | undefined) => {
    setSupplier(prev => ({
      ...prev,
      productType: data?.type,
    }));
    if (data?.type === PROVIDED_TYPE.PACK_DATA) {
      form.setFieldsValue({
        type: data?.supplierId,
        code: data?.code,
        name: data?.name,
        price: formatPrice(data?.price),
        maxCommissionShare: formatPrice(Number(data?.maxCommissionShare)),
        cycle: data?.cycle,
        calling: data?.calling,
        sms: data?.sms,
        dataTraffic: data?.dataTraffic,
        description: data?.description,
      });
    }
    if (data?.type === PROVIDED_TYPE.COURSE) {
      form.setFieldsValue({
        type: data?.supplierId,
        productOriginId: data?.productOriginId,
        name: data?.name,
        price: formatPrice(data?.price),
        maxCommissionShare: formatPrice(Number(data?.maxCommissionShare)),
      });
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: data => {
          handleFillData(data);
          setDataDetails(data);
          setOpen(true);
        },
      };
    },
    []
  );

  return (
    <DrawerForm
      title={'Thông tin sản phẩm'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 450,
      }}
      form={form}
      open={open}
      drawerProps={{
        destroyOnClose: false,
        onClose: onClose,
      }}
      submitter={false}>
      <Form.Item
        label="Nhà cung cấp"
        name="type"
        className="mb-3"
        rules={[{required: true, message: 'Vui lòng chọn nhà cung cấp !'}]}>
        <Select
          className="w-[calc(100%)]"
          placeholder={'Chọn nhà cung cấp'}
          disabled
          options={suppliers.map(item => ({
            value: item.id,
            label: item.name,
            supplierId: item.id,
            supplierCode: item.code,
            type: item.type,
          }))}
        />
      </Form.Item>
      {supplier?.productType === PROVIDED_TYPE.PACK_DATA ? (
        <>
          <LayoutGeneral form={form} isEdit isDisable />
          <LayoutDetails isDisable />
        </>
      ) : (
        <>
          <Form.Item
            label="Danh sách packages MomEdu:"
            name="productOriginId"
            className="mb-4"
            rules={[
              {required: true, message: 'Vui lòng chọn packages MomEdu !'},
            ]}>
            <Select
              allowClear
              className="w-[calc(100%)]"
              placeholder="Chọn khoá học"
              options={listCourse.map(item => ({
                value: item.id,
                label: item.name,
                code: item.code,
                duration: item.duration,
                course: item.course,
                price: item.actualPrice,
              }))}
              disabled
            />
          </Form.Item>
          <Form.Item label="Tên gói:" name="name" className="mb-4">
            <Input placeholder="Nhập tên gói..." className="w-full" disabled />
          </Form.Item>
          <Form.Item label="Giá bán (VNĐ):" name="price" className="mb-4">
            <Input
              placeholder="Nhập giá bán..."
              className="w-[calc(100%)]"
              disabled
            />
          </Form.Item>
          <Form.Item
            label="Hoa hồng (VNĐ):"
            name="maxCommissionShare"
            rules={[{required: true, message: 'Vui lòng nhập hoa hồng !'}]}
            className="mb-4">
            <Input
              placeholder="Hoa hồng..."
              className="w-[calc(100%)]"
              disabled
            />
          </Form.Item>
        </>
      )}
    </DrawerForm>
  );
};

export const DetaillModal = forwardRef(ModalDetail);
