import {RESPONSE_CODE} from '@/network/constants';
import {paymentServices} from '@/network/services/payments';
import {DEFAULT_FORMAT_DATE} from '@/utils/constants';
import {isNullOrEmpty} from '@/utils/stringUtils';
import {message} from 'antd';
import moment from 'moment';
import React, {useEffect} from 'react';

type FilterProps = {
  size: number;
  page: number;
  date?: string;
  fromDate?: string;
  toDate?: string;
  status?: number;
};

type stateBalanceHistoryType = {
  balanceHistory: any;
  metadata: MetadataInterface | undefined;
  filters: FilterProps;
  loading: boolean;
  isRefresh: boolean;
};

const defaultFilters = {
  size: 10,
  page: 0,
  date: undefined,
  fromDate: undefined,
  toDate: undefined,
};

export const useBalanceHistory = () => {
  const [stateBalanceHistory, setStateBalanceHistory] =
    React.useState<stateBalanceHistoryType>({
      balanceHistory: [],
      metadata: undefined,
      filters: defaultFilters,
      loading: false,
      isRefresh: false,
    });

  const actionRef = React.useRef<any>();

  useEffect(() => {
    if (stateBalanceHistory?.isRefresh) getListBalanceHistory();
  }, [stateBalanceHistory?.isRefresh]);

  const onPageChange = (mPage: number, mSize: number) => {
    setStateBalanceHistory(prevState => ({
      ...prevState,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const getListBalanceHistory = async () => {
    setStateBalanceHistory(prevState => ({
      ...prevState,
      loading: true,
    }));
    const params = {
      page: stateBalanceHistory?.filters?.page,
      size: stateBalanceHistory?.filters?.size,
      fromDate: !isNullOrEmpty(stateBalanceHistory?.filters?.fromDate)
        ? moment(stateBalanceHistory.filters.fromDate)
            .startOf('day')
            .format(DEFAULT_FORMAT_DATE)
        : undefined,
      toDate: !isNullOrEmpty(stateBalanceHistory?.filters?.toDate)
        ? moment(stateBalanceHistory.filters.toDate)
            .endOf('day')
            .format(DEFAULT_FORMAT_DATE)
        : undefined,
    };
    const res = await paymentServices.listingBalanceHistory(params);

    if (res?.status === RESPONSE_CODE.SUCCESS) {
      const {data, page, size, total, totalPages} = res?.data;
      setStateBalanceHistory(prevState => ({
        ...prevState,
        balanceHistory: data,
        metadata: {
          totalPages: totalPages,
          page: page,
          size: size,
          total: total,
        },
      }));
      setStateBalanceHistory(prevState => ({
        ...prevState,
        loading: false,
        isRefresh: false,
      }));
    } else {
      setStateBalanceHistory(prevState => ({
        ...prevState,
        loading: false,
        isRefresh: false,
      }));
      message.error('Có lỗi xảy ra');
    }
  };

  const onFilterDates = (values: any) => {
    setStateBalanceHistory((prevState: stateBalanceHistoryType) => ({
      ...prevState,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        fromDate: values?.[0].format('YYYY-MM-DD'),
        toDate: values?.[1].format('YYYY-MM-DD'),
        page: 0,
      },
    }));
  };

  return {
    stateBalanceHistory,
    getListBalanceHistory,
    onPageChange,
    onFilterDates,
    actionRef,
  };
};
