export const API_URL = {
  status: 'status',
  commissionFee: 'collaborator/config/commission_fee',
};

export const API_URL_AUTH = {
  // auth
  AUTH: 'auth',
  LOGIN: 'login',
  LOGOUT: 'logout',
  COLLABORATORS: 'collaborators',
  USER: 'users',
  REGISTER: 'register',
  REGISTER_OTP: 'send-otp',
  VERIFY_OTP: 'verify-otp',
  RESEND_OTP: 'resend-otp',
  //products
  PRODUCT: 'products',
  COURSE: 'source-products',
  AFFILIATE: 'generate-link-affiliate',
  // campaign
  CAMPAIGN: 'campaigns',
  CONFIG: 'config',
  SHARE_VALUE: 'share_value_percent',
};

export const API_URL_ACCOUNT = {
  USER: 'users',
  CTV: 'collaborators',
  FILTER: 'filter',
  LIST_MERCHANT: 'list-merchant',
  RESET_PASSWORD: 'reset-password',
  changePassword: 'auth/change-password',
  partner: 'partner',
  collaborators: 'collaborators',
  wallet: 'wallet',
  enable: 'enable',
  disable: 'disable',
};

export const API_URL_AGENT = {
  agent: 'agent',
  AGENT: 'agents',
  LIST_CODE: 'list-agent-code',
};

export const API_URL_UPLOAD = {
  ATTACHMENTS: 'attachments',
  UPLOAD: 'upload',
};

export const API_URL_ROLE = {
  ROLES: 'roles',
};
export const API_URL_REPORT = {
  report: 'report',
  revenue: 'revenue',
  total: 'total',
  agents: 'agents',
  order: 'order',
  count: 'count',
  campaigns: 'campaigns',
  products: 'products',
  suppliers: 'suppliers',
  collaborator: 'collaborator',
  reportCampaignAgent: 'report/campaigns/products/agent',
};

export const API_URL_ORDER = {
  ORDER: 'orders',
  LIST: 'list',
  DETAILS: 'detail',
};

export const API_URL_DOCUMENTS = {
  documents: 'documents',
};

export const API_SEARCH = {
  SEARCH: 'pack-data/search',
  REGISTER_PACK: 'register-pack',
  CREATE_REQUEST: 'create-request',
  GET_CAMPAIGN: 'get-available-campaign',
  CUSTOMER: 'customer',
  REGISTER: 'register',
};

export const API_URL_PAYMENT = {
  WALLET: 'wallet',
  REQUEST_WITHDRAW: 'request-withdrawal',
  FILTER_WITHDRAW: 'filter-withdrawal',
  FILTER_WITHDRAW_BY_USER: 'filter-withdrawal-by-user',
  BALANCE_HISTORY: 'balance-history',
  EXPORT_PAYMENT: 'withdrawal/export',
  IMPORT_PAYMENT: 'withdrawal/import',
  CANCEL_PAYMENT: 'cancel-withdrawal',
};

export const IMPORT_EXPORT = {
  BASE: 'import-export',
  IMPORT: 'import',
  LIST: 'list',
};

export const CMS = {
  POST: 'posts',
  BASE: 'cms',
  CATEGORY: 'category',
  HASHTAG: 'hashtag',
};

export const API_URL_NOTIFICATION = {
  notifications: 'notifications',
  me: 'me',
  schedules: 'schedules',
};

export const ORGANIZATIONS = {
  region_company: 'region-company',
  branch: 'branch',
  structure: 'structure',
  structure_for_search: 'structure-for-search'
};
