import {isRoleCTV, isUserHaveOneOfPermissions} from '@/utils';
import {CloseOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Col, Row, Tabs, message} from 'antd';
import {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {OrderTab} from './OrderTab';
import {ordersServices} from '@/network/services/orders';
import {ROLES} from '@/utils/enum';

export const Orders: React.FC = () => {
  if (isRoleCTV()) {
    return <OrderTab isAgentSold={false} />;
  }

  const [fileObj, setFileObj] = useState<any>();
  const [loadingImport, setLoadingImport] = useState<boolean>(false);

  const onDrop = useCallback((acceptedFiles: any) => {
    if (acceptedFiles[0]) {
      setFileObj(acceptedFiles[0]);
    }
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'text/csv': ['.csv'],
    },
  });

  const onImportData = async () => {
    if (fileObj) {
      const resp = await ordersServices.importMOCode(fileObj);
      if (resp?.status === 200) {
        message.success(resp?.data ?? 'Import Data thành công');
        setLoadingImport(false);
        setFileObj(null);
      } else {
        message.error(resp?.data ?? 'Import Data thất bại');
        setLoadingImport(false);
      }
    } else {
      message.error('Cần chọn file để thao tác');
    }
  };

  return (
    <Tabs
      tabBarExtraContent={
        isUserHaveOneOfPermissions([ROLES.ADMIN, ROLES.AM_LEADER]) && (
          <Row gutter={[0, 12]} className="excelField">
            {!fileObj ? (
              <Col className="accountantBtn" {...getRootProps()}>
                <div>
                  <input {...getInputProps()} />
                  <Button
                    key="button"
                    icon={<UploadOutlined />}
                    // onClick={onCreateChangeStatus}
                    type="primary">
                    Import Mã MO
                  </Button>
                </div>
              </Col>
            ) : (
              <Row gutter={[0, 12]} className="accountantBtn excelView">
                <div className="excelContainer">
                  <div {...getRootProps()} className="txtExcelName">
                    {fileObj?.name}
                  </div>
                  <CloseOutlined
                    onClick={() => setFileObj(null)}
                    className="btn-remove-excel-file"
                  />
                </div>
                <Button
                  className="mgl-10"
                  loading={loadingImport}
                  icon={<UploadOutlined />}
                  onClick={onImportData}
                  type="primary">
                  Import
                </Button>
              </Row>
            )}
          </Row>
        )
      }>
      <Tabs.TabPane tab="Đơn hàng của Đại lý" key="item-1">
        <OrderTab isAgentSold={true} key={'tab-1'} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Đơn hàng của Cộng tác viên" key="item-2">
        <OrderTab isAgentSold={false} key={'tab-2'} />
      </Tabs.TabPane>
    </Tabs>
  );
};
