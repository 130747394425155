import {ACCESS_TOKEN} from '@/utils/enum';
import {ERROR_API_CODE, RESPONSE_CODE} from './constants';
import {onLogout} from '@/utils';

export const AccessTokenInterceptor = {
  addAccessToken: (config: any) => {
    const token = localStorage.getItem(ACCESS_TOKEN.ACCESS_TOKEN);
    if (token) {
      const headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        Language: 'vi',
      };
      config.headers = headers;
    }
    return config;
  },
  onRejected: (error: any) => {
    return Promise.resolve(error);
  },
};

export const RequestResponseInterceptor = {
  request: (response: any) => {
    return response;
  },
  requestErr: (error: any) => {
    const originalRequest = error.config;
    if (error?.response?.status === RESPONSE_CODE.UNAUTHORIZED) {
      if (
        error?.response?.data?.statusCode === ERROR_API_CODE.NEED_UPDATE_CODE &&
        !originalRequest._retry
      ) {
        onLogout();
      }
      return Promise.reject(error?.response);
    }
    return Promise.resolve(error?.response);
  },
};
