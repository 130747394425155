import {DEFAULT_PAGE_SIZE} from '@/utils/constants';
import {formatNumberInSingleLetterNotation} from '@/utils/stringUtils';

export default (page: number) => [
  {
    title: 'STT',
    dataIndex: 'index',
    key: 'index',
    render: (_data: any, _report: any, index: number) =>
      page * DEFAULT_PAGE_SIZE + index + 1,
  },
  {
    title: 'Tên chiến dịch',
    dataIndex: 'campaignName',
    key: 'campaignName',
  },
  {
    title: 'Sản phẩm',
    dataIndex: 'productName',
    key: 'productName',
  },
  {
    title: 'Số đơn hàng',
    dataIndex: 'totalOrder',
    key: 'totalOrder',
  },
  {
    title: 'Hoa hồng được chiếu khấu',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    render: (value: number) => formatNumberInSingleLetterNotation(value),
  },
  {
    title: 'Hoa hồng chia sẻ',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
  },
];
