import {RESPONSE_CODE} from '@/network/constants';
import {cmsServices} from '@/network/services/cms';
import {isNullOrEmpty} from '@/utils/stringUtils';
import {ActionType} from '@ant-design/pro-components';
import {message} from 'antd';
import moment from 'moment';
import React, {useEffect} from 'react';

type FilterProps = {
  size: number;
  page: number;
  name: string;
  categoryId: number | undefined;
  modifiedDate: string | undefined;
};

type statePaymentType = {
  post: PostDetailProps[] | undefined;
  loading: boolean;
  isRefresh: boolean;
  categories: categoryPostProps[] | undefined;
  hashtags: HashtagPostInterface[] | undefined;
  filters: FilterProps;
  metadata: MetadataInterface | undefined;
};

const defaultFilters = {
  size: 25,
  page: 0,
  name: '',
  categoryId: undefined,
  modifiedDate: '',
};

export const useCMS = () => {
  const [data, setData] = React.useState<statePaymentType>({
    post: undefined,
    filters: defaultFilters,
    loading: false,
    isRefresh: false,
    categories: undefined,
    hashtags: undefined,
    metadata: undefined,
  });

  const actionRef = React.useRef<ActionType>();

  useEffect(() => {
    if (data?.isRefresh) {
      getListPosts();
    }
  }, [data?.isRefresh]);

  useEffect(() => {
    getListCtategories();
    getListHashtag();
  }, []);

  const getListPosts = async () => {
    setData(prevState => ({
      ...prevState,
      loading: true,
    }));
    const params = {
      name: data?.filters?.name,
      categoryId: data?.filters?.categoryId,
      modifiedDate: data?.filters?.modifiedDate,
    };
    const res = await cmsServices.getListPost(params);

    if (res?.status === RESPONSE_CODE.SUCCESS) {
      const {data, totalPages, page, size, total} = res?.data;
      setData(prevState => ({
        ...prevState,
        post: data,
        loading: false,
        isRefresh: false,
        metadata: {
          totalPages: totalPages,
          page: page,
          size: size,
          total: total,
        },
      }));
    } else {
      setData(prevState => ({
        ...prevState,
        loading: false,
        isRefresh: false,
      }));
      message.error(res?.data ?? 'Có lỗi xảy ra');
    }
  };

  const getListCtategories = async () => {
    setData(prevState => ({
      ...prevState,
      loading: true,
    }));
    const res = await cmsServices.getListCategory();
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setData(prevState => ({
        ...prevState,
        categories: res?.data,
        loading: false,
        isRefresh: false,
      }));
    } else {
      setData(prevState => ({
        ...prevState,
        loading: false,
        isRefresh: false,
      }));
      message.error(res?.data ?? 'Có lỗi xảy ra');
    }
  };

  const getListHashtag = async () => {
    setData(prevState => ({
      ...prevState,
      loading: true,
    }));
    const res = await cmsServices.getHashtags();
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setData(prevState => ({
        ...prevState,
        hashtags: res?.data,
        loading: false,
        isRefresh: false,
      }));
    } else {
      setData(prevState => ({
        ...prevState,
        loading: false,
        isRefresh: false,
      }));
      message.error(res?.data ?? 'Có lỗi xảy ra');
    }
  };

  const onChangeName = (value: string) => {
    setData(prevState => ({
      ...prevState,
      loading: true,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        name: value,
      },
    }));
  };

  const onChangeCategory = (value: number) => {
    setData(prevState => ({
      ...prevState,
      loading: true,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        categoryId: value,
      },
    }));
  };

  const onChangeDate = (value: string) => {
    setData(prevState => ({
      ...prevState,
      loading: true,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        modifiedDate: !isNullOrEmpty(value)
          ? moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss')
          : undefined,
      },
    }));
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setData((prevState: any) => ({
      ...prevState,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  return {
    data,
    actionRef,
    getListPosts,
    onChangeName,
    onChangeCategory,
    onChangeDate,
    onPageChange,
  };
};
