import {AxiosResponse} from 'axios';
import {API_URL_NOTIFICATION} from '../apiUrl';
import {getRequestUrl} from '../utils';
import {NetWork} from '..';
import {deleteParamsNotUsing} from '@/utils/stringUtils';

const createNotification = (body: BodyCreateNotification) => {
  const url = getRequestUrl(API_URL_NOTIFICATION.notifications, {
    parentId: API_URL_NOTIFICATION.schedules,
  });
  return NetWork.post(url, body);
};

const getSchedules = (
  params?: SchedulesFilter
): Promise<AxiosResponse<any>> => {
  deleteParamsNotUsing(params);
  const url = getRequestUrl(API_URL_NOTIFICATION.notifications, {
    parentId: API_URL_NOTIFICATION.schedules,
  });
  return NetWork.get(url, params);
};

const detailSchedule = (id: number) => {
  const url = getRequestUrl(API_URL_NOTIFICATION.notifications, {
    parentId: API_URL_NOTIFICATION.schedules,
    partial: id,
  });
  return NetWork.get(url);
};

const editSchedule = (id: number, body: BodyCreateNotification) => {
  const url = getRequestUrl(API_URL_NOTIFICATION.notifications, {
    parentId: API_URL_NOTIFICATION.schedules,
    partial: id,
  });
  return NetWork.patch(url, body);
};

const notifications = (params: DefaultFiltersInterface) => {
  const url = getRequestUrl(API_URL_NOTIFICATION.notifications, {
    parentId: API_URL_NOTIFICATION.me,
  });
  return NetWork.get(url, params);
};

const detailNotification = (id: number) => {
  const url = getRequestUrl(API_URL_NOTIFICATION.notifications, {
    parentId: id,
  });
  return NetWork.get(url);
};

export const notificationServices = {
  createNotification,
  getSchedules,
  detailSchedule,
  editSchedule,
  notifications,
  detailNotification,
};
