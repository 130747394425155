import {Moment} from 'moment';
import {ACCESS_TOKEN, LOCAL_STORAGE_KEY, ROLES} from './enum';
import {DEFAULT_FORMAT_DATE} from './constants';
import moment from 'moment';

/**
 * This function convert date to timestamp
 * @param phone is phone number
 * @returns {string}
 */
export const replacePhoneNumber = (phone: string): string => {
  return phone.replace(/^0/g, '');
};

/**
 * This function check have permissions
 * @param permissions is phone number
 * @returns {string}
 */
export const isUserHaveOneOfPermissions = (permissions: string[]): boolean => {
  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);
  return permissions.includes(userRole ?? '');
};

export const isRoleAgent = () => {
  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);
  return userRole === ROLES.AGENT;
};

export const isRoleCTV = () => {
  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);
  return userRole === ROLES.COLLABORATOR;
};

export const isRoleAgentCtv = () => {
  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);
  return userRole === ROLES.AGENT || userRole === ROLES.COLLABORATOR;
};

export const convertDate = (date?: Moment) =>
  date ? moment(date.valueOf()).format(DEFAULT_FORMAT_DATE) : undefined;

export const onLogout = () => {
  localStorage.removeItem(ACCESS_TOKEN.ACCESS_TOKEN);
  window.location.href = '/';
};
