import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';
import {Tooltip} from 'antd';
import {FC} from 'react';

type EyeIconProps = {
  status: boolean;
  onClick: () => void;
  title?: string;
};

const EyeIcon: FC<EyeIconProps> = ({status, title, onClick}) => (
  <Tooltip title={title}>
    <span className={`text-[#1677ff] cursor-pointer`} onClick={onClick}>
      {status ? (
        <EyeInvisibleOutlined className="text-[17px]" />
      ) : (
        <EyeOutlined className="text-[18px]" />
      )}
    </span>
  </Tooltip>
);

export default EyeIcon;
