import {RESPONSE_CODE} from '@/network/constants';
import {authServices} from '@/network/services/auth';
import {ROUTERS} from '@/utils/constants';
import {ACCESS_TOKEN} from '@/utils/enum';
import {callUserInformation} from '@/utils/helper';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {LoginFormPage, ProCard, ProFormText} from '@ant-design/pro-components';
import {notification} from 'antd';
import Link from 'antd/es/typography/Link';
import {useState} from 'react';

export const Login: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const handleLogin = async (values: LoginInterface) => {
    setLoading(true);
    const res = await authServices.login(values);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      if (res?.data?.needUpdatePassword) {
        window.location.href = ROUTERS.ChangePassword.path;
      } else {
        const {access_token} = res?.data;
        callUserInformation();
        localStorage.setItem(ACCESS_TOKEN.ACCESS_TOKEN, access_token);
        setLoading(false);
      }
    } else {
      notification.error({
        message: res?.data?.message,
      });
      setLoading(false);
    }
  };

  return (
    <ProCard
      style={{
        backgroundColor: 'white',
        height: 'calc(100vh - 20px)',
      }}>
      <LoginFormPage
        backgroundImageUrl="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
        logo="https://seekicon.com/free-icon-download/octocat_1.png"
        title="Gói cước viễn thông"
        subTitle={<div>Đăng nhập</div>}
        submitter={{
          searchConfig: {
            submitText: 'Đăng nhập',
          },
          submitButtonProps: {loading},
        }}
        onFinish={handleLogin}>
        <>
          <ProFormText
            name="username"
            fieldProps={{
              size: 'large',
              prefix: <UserOutlined className={'prefixIcon'} />,
            }}
            placeholder="Tên đăng nhập/Số điện thoại"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập Tên tài khoản!',
              },
            ]}
          />
          <ProFormText.Password
            name="password"
            fieldProps={{
              size: 'large',
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Mật khẩu'}
            rules={[{required: true, message: 'Vui lòng nhập Mật khẩu!'}]}
          />
          <div className="flex justify-end mb-1">
            <Link href={ROUTERS.ChangePassword.path}>Đổi mật khẩu</Link>
          </div>
        </>
      </LoginFormPage>
    </ProCard>
  );
};
