import {DEFAULT_PASSWORD} from '@/utils/constants';
import {deleteParamsNotUsing} from '@/utils/stringUtils';
import axios, {AxiosResponse} from 'axios';
import {NetWork} from '..';
import {API_URL_ACCOUNT, API_URL_AGENT, API_URL_AUTH} from '../apiUrl';
import {RESPONSE_CODE} from '../constants';
import {getRequestUrl} from '../utils';

export const accounts = (params?: GetListAccountType) => {
  const url = getRequestUrl(API_URL_ACCOUNT.USER, {
    parentId: API_URL_ACCOUNT.FILTER,
  });
  deleteParamsNotUsing(params);
  return NetWork.get(url, params);
};

export const listAgency = async () => {
  const url = getRequestUrl(API_URL_AGENT.AGENT, {
    parentId: API_URL_AGENT.LIST_CODE,
  });
  const res = await NetWork.get(url);
  return res.data;
};

export const listManager = async () => {
  const url = getRequestUrl(API_URL_ACCOUNT.USER, {
    parentId: API_URL_ACCOUNT.LIST_MERCHANT,
  });
  const res = await NetWork.get(url);
  return res.data;
};

export const listBank = async () => {
  const res = await axios.get('https://api.vietqr.io/v2/banks');
  return res.data;
};

export const createAccount = async (req: CreateAccountType) => {
  const url = getRequestUrl(API_URL_ACCOUNT.USER);
  return await NetWork.post(url, req);
};

export const updateAccount = async (id: number, req: UpdateAccountType) => {
  const url = getRequestUrl(API_URL_ACCOUNT.USER, {parentId: id});
  return await NetWork.patch(url, req);
};

export const updateAccountCTV = async (id: number, req: any) => {
  const url = getRequestUrl(API_URL_ACCOUNT.CTV, {parentId: id});
  return await NetWork.put(url, req);
};

export const resetPassword = async (id: number) => {
  const url = getRequestUrl(API_URL_ACCOUNT.USER, {
    parentId: id,
    subId: API_URL_ACCOUNT.RESET_PASSWORD,
  });
  return await NetWork.post(url, {
    password: DEFAULT_PASSWORD,
  });
};

export const getInfoUser = async () => {
  const url = getRequestUrl(API_URL_ACCOUNT.USER);
  const res = await NetWork.get(url);
  if (res.status === RESPONSE_CODE.SUCCESS) {
    return res.data;
  }
  return null;
};

export const updateCTV = async (req: UpdateCtvType, id?: number) => {
  const url = getRequestUrl(API_URL_AUTH.COLLABORATORS, {
    parentId: id,
  });
  const res = await NetWork.put(url, req);
  return res;
};

export const updateUSER = async (req: UpdateCtvType, id?: number) => {
  const url = getRequestUrl(API_URL_AUTH.USER, {
    parentId: id,
  });
  const res = await NetWork.patch(url, req);
  return res;
};

const collaborators = (
  params: CollaboratorParams
): Promise<AxiosResponse<CollaboratorsResponse>> => {
  deleteParamsNotUsing(params);
  const url = getRequestUrl(API_URL_ACCOUNT.USER, {
    parentId: API_URL_ACCOUNT.partner,
  });
  return NetWork.get(url, params);
};

const createCollaborators = (
  params: CollaboratorParams
): Promise<AxiosResponse<any>> => {
  deleteParamsNotUsing(params);
  const url = getRequestUrl(API_URL_ACCOUNT.collaborators);
  return NetWork.post(url, params);
};

const updateCollaborators = (
  params: CollaboratorParams
): Promise<AxiosResponse<any>> => {
  deleteParamsNotUsing(params);
  const url = getRequestUrl(API_URL_ACCOUNT.collaborators);
  return NetWork.put(url, params);
};

const changePassword = (
  body: ChangePasswordForm
): Promise<AxiosResponse<ChangePasswordResponse>> => {
  const url = getRequestUrl(API_URL_ACCOUNT.changePassword);
  return NetWork.post(url, body);
};

const paymentPermission = (
  userId: number,
  isEnablePayment: boolean
): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(API_URL_ACCOUNT.wallet, {
    parentId: userId,
    partial: isEnablePayment ? API_URL_ACCOUNT.disable : API_URL_ACCOUNT.enable,
  });
  return NetWork.put(url);
};

const collaboratorsDetail = (
  id: number
): Promise<AxiosResponse<CollaboratorsResponse>> => {
  const url = getRequestUrl(API_URL_ACCOUNT.USER, {
    parentId: API_URL_ACCOUNT.partner,
    partial: id,
  });
  return NetWork.get(url);
};

const accountsServices = {
  accounts,
  collaborators,
  changePassword,
  createCollaborators,
  updateCollaborators,
  paymentPermission,
  collaboratorsDetail,
};

export default accountsServices;
