import {DEFAULT_PAGE_SIZE} from '@/utils/constants';
import moment from 'moment';
import {MomentInput} from 'moment';
import {Space, Tooltip} from 'antd';
import {EditOutlined, EyeOutlined} from '@ant-design/icons';
import {ModalDetailRef} from '../Accounts/ModalDetailInfo';
import {isRoleAgentCtv, isUserHaveOneOfPermissions} from '@/utils';
import {ROLES} from '@/utils/enum';
import {ColumnsType} from 'antd/es/table';

export default (
  page: number,
  modalDetailRef: React.RefObject<ModalDetailRef>,
  isMobile: boolean,
  // onChangePermissionPayment: (id: number, isHidden: true | false) => void,
  hanldleShowDetail: (id: number) => void
): ColumnsType<AccountType> & any => {
  return [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      align: 'center',
      render: (_data: any, _report: any, index: number) =>
        page * DEFAULT_PAGE_SIZE + index + 1,
    },
    {
      title: 'Mã đại lý',
      dataIndex: 'agentCode',
      align: 'center',
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'userName',
      align: 'center',
    },
    {
      title: 'Họ và tên',
      dataIndex: 'fullname',
      align: 'center',
    },
    {
      title: 'Chức vụ',
      dataIndex: 'role',
      align: 'center',
      hidden: isRoleAgentCtv(),
    },
    {
      title: 'CCCD',
      dataIndex: 'identityCode',
      align: 'center',
      hidden: isRoleAgentCtv(),
    },
    {
      title: 'TK ngân hàng',
      dataIndex: 'bankAccount',
      align: 'center',
      hidden: isRoleAgentCtv(),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      align: 'center',
      render: (_: any, record: {createdDate: MomentInput}) => {
        return record.createdDate
          ? moment(record.createdDate).format('DD/MM/YYYY')
          : '-';
      },
    },
    {
      title: 'Số điện thọại',
      dataIndex: 'phoneNumber',
      align: 'center',
    },
    // {
    //   title: 'Quyền thanh toán',
    //   key: 'isEnablePayment',
    //   align: 'center',
    //   hidden: !isUserHaveOneOfPermissions([ROLES.ADMIN]),
    //   width: 130,
    //   render: (_data: any, record: any) => (
    //     <Switch
    //       size="small"
    //       defaultChecked={!!record?.isEnablePayment}
    //       onChange={onChangePermissionPayment?.bind(
    //         null,
    //         record.userId,
    //         record?.isEnablePayment ? true : false
    //       )}
    //     />
    //   ),
    // },
    {
      title: 'Thao tác',
      key: 'option',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (_data: any, record: any) => (
        <Space size={10}>
          {isUserHaveOneOfPermissions([
            ROLES.ADMIN,
            ROLES.AM_LEADER,
            ROLES.AGENT,
          ]) && (
            <Tooltip title={'Sửa'} trigger={isMobile ? 'contextMenu' : 'hover'}>
              <EditOutlined
                onClick={hanldleShowDetail?.bind(null, record?.userId)}
                className="text-[#1677ff] cursor-pointer text-[17px] mr-2"
              />
            </Tooltip>
          )}
          <Tooltip
            title={'Xem toàn bộ thông tin'}
            trigger={isMobile ? 'contextMenu' : 'hover'}>
            <EyeOutlined
              onClick={() => {
                modalDetailRef.current?.setOpenModal(true, record);
              }}
              className="text-[#1677ff] cursor-pointer text-[17px]"
            />
          </Tooltip>
        </Space>
      ),
    },
  ].filter(item => !item.hidden);
};
