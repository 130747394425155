import {ProColumns} from '@ant-design/pro-components';
import moment from 'moment';

const getColumnsConfig = (actionRef?: any) => {
  // const role = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);

  const getStatusColor = (
    num: number
  ):
    | {name: string; color: string; background: string; borderColor: string}
    | undefined => {
    switch (num) {
      case 0:
        return {
          name: 'Chờ xử lý',
          color: 'rgba(0,0,0,.88)',
          background: '#FAFAFA',
          borderColor: '#D9D9D9',
        };
      case 1:
        return {
          name: 'Đang xử lý',
          color: '#0958d9',
          background: '#e6f4ff',
          borderColor: '#91caff',
        };
      case 2:
        return {
          name: 'Thành công',
          color: '#389e0d',
          background: '#f6ffed',
          borderColor: '#b7eb8f',
        };
      case 3:
        return {
          name: 'Thất bại',
          color: '#ff4d4f',
          background: '#fff2f0',
          borderColor: '#ffccc7',
        };

      default:
        break;
    }
  };

  const columns: ProColumns<PaymentItemInterface>[] = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 80,
      render: (_, __, indx) => {
        const current = actionRef?.current?.pageInfo?.current || 1;
        const size = actionRef?.current?.pageInfo?.pageSize || 10;
        return (current - 1) * size + indx + 1;
      },
    },
    {
      title: 'Tên file',
      dataIndex: 'fileName',
      key: 'fileName',
      align: 'center',
    },
    {
      title: 'Người yêu cầu',
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'center',
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
    },
    {
      title: 'Thời gian yêu cầu',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      render: (_, record) => {
        return moment
          .unix(Number(record.createdDate))
          .format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Thời gian xử lý',
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      align: 'center',
      render: (_, record) => {
        return moment
          .unix(Number(record.modifiedDate))
          .format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      // width: 165,
      render: (_, record) => {
        const obj = getStatusColor(record?.status ?? 6);
        return (
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
            }}>
            <div
              style={{
                background: obj?.background,
                outlineColor: obj?.borderColor,
                alignContent: 'center',
              }}
              className={'statusContainer'}>
              <p
                className="txtStatus"
                style={{
                  color: obj?.color,
                }}>
                {obj?.name}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Lý do',
      dataIndex: 'reason',
      key: 'reason',
      align: 'center',
    },
  ];

  return columns;
};

export default getColumnsConfig;
