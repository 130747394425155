import {Dispatch, createContext, useReducer} from 'react';
import {reducer} from './reducer';
import {initialState} from './state';

export type Action = {
  type: string;
  payload: any;
};

type ContextType = {
  appState: AppStorage;
  dispatch: Dispatch<Action>;
};

// Create a new context
export const AppContext = createContext<ContextType>({
  appState: initialState,
  dispatch: () => null,
});

export const AppProvider = ({children}: any) => {
  const [appState, dispatch] = useReducer(reducer, initialState);
  return (
    <AppContext.Provider value={{appState, dispatch}}>
      {children}
    </AppContext.Provider>
  );
};
export default AppContext;
