import {AxiosResponse} from 'axios';
import {NetWork} from '..';
import {CMS} from '../apiUrl';
import {getRequestUrl} from '../utils';
import {deleteParamsNotUsing} from '@/utils/stringUtils';

const getListPost = (params: {
  name?: string;
  categoryId?: number;
  modifiedDate?: string;
}): Promise<AxiosResponse<any>> => {
  deleteParamsNotUsing(params);
  const url = getRequestUrl(
    CMS.BASE,
    {
      parentId: CMS.POST,
    },
    params
  );
  return NetWork.get(url);
};

const getListCategory = (): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(CMS.BASE, {
    parentId: CMS.POST,
    action: CMS.CATEGORY,
  });
  return NetWork.get(url);
};

const getPostDetail = (id: number): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(CMS.BASE, {
    parentId: CMS.POST,
    action: id,
  });
  return NetWork.get(url);
};

const editPostDetail = (
  id: number,
  params: {
    name?: string;
    description?: string;
    icon?: string;
    thumbnail?: string;
    content?: string;
    position?: number;
    enable?: string;
    categoryId?: string;
    categoryName?: string;
    hashTagIds?: any;
    createdDate?: string;
    modifiedDate?: string;
    createdBy?: string;
    modifiedBy?: string;
    views?: number;
    note?: string;
  }
): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(CMS.BASE, {
    parentId: CMS.POST,
    action: id,
  });
  return NetWork.patch(url, params);
};

const createPost = (params: any): any => {
  deleteParamsNotUsing(params);
  const url = getRequestUrl(CMS.BASE, {
    parentId: CMS.POST,
  });
  return NetWork.post(url, params);
};

const getHashtags = (): Promise<AxiosResponse<any>> => {
  const url = getRequestUrl(CMS.BASE, {
    parentId: CMS.HASHTAG,
  });
  return NetWork.get(url);
};

export const cmsServices = {
  getListPost,
  getListCategory,
  getPostDetail,
  getHashtags,
  editPostDetail,
  createPost,
};
