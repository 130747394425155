import {OrderDetailModalRef} from '@/containers/Orders/OrdersDetails';
import {RESPONSE_CODE} from '@/network/constants';
import {ordersServices} from '@/network/services/orders';
import {DATE_FORMAT, DEFAULT_FORMAT_DATE} from '@/utils/constants';
import {ActionType} from '@ant-design/pro-components';
import {message} from 'antd';
import {debounce} from 'lodash';
import moment from 'moment';
import React, {Ref} from 'react';

type State = {
  orders: OrderItemInterface[];
  filters: FilterOrderParams;
  metadata: MetadataInterface | undefined;
  loading: boolean;
  totalRevenue: number;
  totalCommission: number;
};

interface UserOrdersInterface {
  state: State;
  orderDetailModalRef: Ref<OrderDetailModalRef> | undefined;
  actionRef: any;
  handleSearch: any;
  onChangeDate: (values: any) => void;
  openDetailModal: (id: number) => void;
  onChangeStatus: (value: number) => void;
  onPageChange: (mPage: number, mSize: number) => void;
}

const defaultFilters: FilterOrderParams = {
  size: 50,
  page: 0,
  query: '',
  fromDate: undefined,
  toDate: undefined,
  status: undefined,
};

export const useOrders = (isAgentSold: boolean): UserOrdersInterface => {
  const actionRef = React.useRef<ActionType>();
  const orderDetailModalRef = React.useRef<OrderDetailModalRef>(null);
  const [state, setState] = React.useState<State>({
    orders: [],
    filters: defaultFilters,
    metadata: undefined,
    loading: true,
    totalRevenue: 0,
    totalCommission: 0,
  });

  React.useEffect(() => {
    getOrders();
  }, []);

  React.useEffect(() => {
    if (state?.loading) {
      getOrders();
    }
  }, [state?.loading]);

  React.useEffect(() => {
    delayedSearch();
    return delayedSearch.cancel;
  }, [state?.filters]);

  const delayedSearch = React.useCallback(
    debounce(() => onSearchData(), 300),
    [state?.filters]
  );

  const onSearchData = debounce(() => {
    getOrders();
  });

  const getOrders = async () => {
    const params: FilterOrderParams = {
      ...state?.filters,
      fromDate: state.filters.fromDate
        ? moment(state.filters.fromDate, DATE_FORMAT)
            .startOf('day')
            .format(DEFAULT_FORMAT_DATE)
        : undefined,
      toDate: state.filters.toDate
        ? moment(state.filters.toDate, DATE_FORMAT)
            .endOf('day')
            .format(DEFAULT_FORMAT_DATE)
        : undefined,
      isAgentSold: isAgentSold,
    };
    const res = await ordersServices.get(params);
    const {
      data,
      page,
      size,
      total,
      totalPages,
      totalRevenue = 0,
      totalCommission = 0,
    } = res?.data;
    if (res.status === RESPONSE_CODE.SUCCESS) {
      setState(prevState => ({
        ...prevState,
        orders: data,
        loading: false,
        metadata: {
          totalPages: totalPages,
          page: page,
          size: size,
          total: total,
        },
        totalRevenue: totalRevenue,
        totalCommission: totalCommission,
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const openDetailModal = async (id: number) => {
    const res = await ordersServices.getDetail(id);
    if (res.status === RESPONSE_CODE.SUCCESS) {
      orderDetailModalRef.current?.open(res?.data);
    } else {
      message.error(res?.data as unknown as string);
    }
  };

  const handleSearch = (value: string) => {
    setState((prevState: State) => ({
      ...prevState,
      loading: true,
      filters: {
        ...prevState.filters,
        query: value,
        page: 0,
      },
    }));
  };

  const onChangeDate = (values: any) => {
    setState((prevState: State) => ({
      ...prevState,
      loading: true,
      filters: {
        ...prevState.filters,
        fromDate: values?.[0].format(DATE_FORMAT),
        toDate: values?.[1].format(DATE_FORMAT),
        page: 0,
      },
    }));
  };

  const onChangeStatus = (value: number) => {
    setState((prevState: State) => ({
      ...prevState,
      loading: true,
      filters: {
        ...prevState.filters,
        status: value,
        page: 0,
      },
    }));
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setState((prevState: State) => ({
      ...prevState,
      loading: true,
      filters: {
        ...prevState.filters,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  return {
    state,
    orderDetailModalRef,
    actionRef,
    handleSearch,
    onChangeDate,
    openDetailModal,
    onChangeStatus,
    onPageChange,
  };
};
