import {STATUS_NOTIFICATION, TARGET_TYPES} from '@/utils/enum';
import {PlusOutlined} from '@ant-design/icons';
import {ProCard} from '@ant-design/pro-components';
import {Button, Col, DatePicker, Row, Select} from 'antd';
import {FC} from 'react';

type Props = {
  onOpenModal: () => void;
  onChangeDates: (_: any, value: string[]) => void;
  onSelectObject: (value: TargetType) => void;
  onSelectStatus: (value: 0 | 1 | undefined) => void;
};

export const NotificationFilters: FC<Props> = ({
  onOpenModal,
  onChangeDates,
  onSelectObject,
  onSelectStatus,
}) => {
  return (
    <ProCard className="px-2.5 mb-2" bordered>
      <Row gutter={[24, 12]}>
        <Col xs={24} sm={12} md={6}>
          <DatePicker.RangePicker
            onChange={onChangeDates}
            className="w-full"
            format={'DD/MM/YYYY'}
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Select
            options={TARGET_TYPES}
            className="w-full"
            placeholder="Lọc theo đối tượng gửi"
            onChange={onSelectObject}
            allowClear
          />
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Select
            options={STATUS_NOTIFICATION}
            placeholder="Lọc theo thông báo"
            onChange={onSelectStatus}
            allowClear
          />
        </Col>
        <Col xs={24} sm={12} md={2}></Col>
        <Col xs={24} sm={12} md={6} className="flex justify-end">
          <Button
            key="button"
            icon={<PlusOutlined />}
            onClick={onOpenModal}
            type="primary">
            Thêm thông báo
          </Button>
        </Col>
      </Row>
    </ProCard>
  );
};
