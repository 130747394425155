import {MO_ACTION, MO_STATUS} from '@/utils/constants';
import {ProCard} from '@ant-design/pro-components';
import {Col, DatePicker, Row, Select} from 'antd';
import './styles.scss';

type Props = {
  onChangeStatus: (status: number) => void;
  onChangeAction: (action: string) => void;
  onChangeDates: (_: any, value: string[]) => void;
};

export const MOFilter = ({
  onChangeStatus,
  onChangeDates,
  onChangeAction,
}: Props) => {
  return (
    <ProCard
      className="ant-pro-table-search ant-pro-table-search-query-filter"
      bordered>
      <Row
        gutter={[10, 12]}
        className="flex"
        style={{width: '100%', paddingLeft: 10}}>
        <Col style={{minWidth: 250}}>
          <DatePicker.RangePicker
            style={{width: '100%', maxWidth: 500}}
            onChange={onChangeDates}
            format={'YYYY-MM-DD'}
            placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
          />
        </Col>
        <Col>
          <Select
            allowClear
            style={{minWidth: 200}}
            placeholder="Trạng thái"
            options={MO_STATUS.map((item: any) => ({
              value: item.id,
              label: item.value,
            }))}
            onChange={onChangeStatus}
          />
        </Col>

        <Col>
          <Select
            allowClear
            style={{minWidth: 200}}
            placeholder="Hành động"
            options={MO_ACTION.map((item: any) => ({
              value: item.id,
              label: item.value,
            }))}
            onChange={onChangeAction}
          />
        </Col>
      </Row>
    </ProCard>
  );
};
