import {NetWork} from '..';
import {API_URL_UPLOAD, CMS} from '../apiUrl';
import {getRequestUrl} from '../utils';

export const uploadImg = async (
  params: {category?: string; file: any},
  config?: any
) => {
  const url = getRequestUrl(API_URL_UPLOAD.ATTACHMENTS, {
    parentId: API_URL_UPLOAD.UPLOAD,
  });
  const res = await NetWork.postFormData(
    url,
    {
      file: params.file,
      category: params.category,
    },
    config
  );
  return res.data;
};

export const uploadCmsImg = async (params: {file: any}, config?: any) => {
  const url = getRequestUrl(CMS.BASE, {
    parentId: API_URL_UPLOAD.ATTACHMENTS,
    action: API_URL_UPLOAD.UPLOAD,
  });
  const res = await NetWork.postFormData(
    url,
    {
      file: params.file,
    },
    config
  );
  return res.data;
};

export const uploadChunk = (params?: any) => {
  return NetWork.post('/api/attachments/chunks', params);
};
