import {SearchOutlined} from '@ant-design/icons';
import {ProCard} from '@ant-design/pro-components';
import {Button, Col, Input, Row} from 'antd';
import React from 'react';

type Props = {
  search: string;
  suppliers: SuppliersInterface[];
  onChangeText: (value: string) => void;
  onSelectSupplier: (value: string) => void;
  handleOnSearch: () => void;
  loading: boolean;
};

export const Header: React.FC<Props> = ({
  search,
  // suppliers,
  onChangeText,
  // onSelectSupplier,
  handleOnSearch,
  loading,
}) => {
  const onChange = (e: any) => {
    onChangeText?.(e.target.value);
  };

  return (
    <ProCard
      className="ant-pro-table-search ant-pro-table-search-query-filter"
      bordered>
      <Row gutter={[24, 12]}>
        <Col xs={24} sm={12} md={8}>
          <Input.Search
            placeholder="Số điện thoại..."
            value={search}
            onChange={onChange}
            allowClear
          />
        </Col>
        {/* <Col xs={24} sm={12} md={4}>
          <Select
            allowClear
            className="w-[calc(100%)]"
            placeholder="Chọn nhà mạng"
            options={suppliers.map(item => ({
              value: item.code,
              label: item.name,
            }))}
            onSelect={onSelectSupplier}
          />
        </Col> */}
        <Col xs={24} sm={12} md={4}>
          <Button
            key="button"
            icon={<SearchOutlined />}
            loading={loading}
            onClick={handleOnSearch}
            type="primary">
            Tra cứu
          </Button>
        </Col>
      </Row>
    </ProCard>
  );
};
