import {Navigate, Outlet, useLocation} from 'react-router-dom';

import {LOCAL_STORAGE_KEY} from '@/utils/enum';

type Props = {
  ignoreRoles: string[];
};

const Auth = ({ignoreRoles}: Props) => {
  const role = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);
  const location = useLocation();

  return ignoreRoles.includes(role || '') ? (
    <Navigate to="/unauthorized" state={{from: location}} replace />
  ) : (
    <Outlet />
  );
};

export default Auth;
