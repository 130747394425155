import {Button, Col, Input, Row, Select} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import React from 'react';
import {isUserHaveOneOfPermissions} from '@/utils';
import {ROLES} from '@/utils/enum';
import {ProCard} from '@ant-design/pro-components';

type Props = {
  search: string;
  agents: AgentItem[];
  onCreateAccount: () => void;
  onCreateAccountCollaborator?: () => void;
  onChangeText: (e: any) => void;
  onSelectAgent: (value: string) => void;
};
const CollaboratorsFilter: React.FC<Props> = ({
  search,
  agents = [],
  onChangeText,
  onCreateAccount,
  onSelectAgent,
  onCreateAccountCollaborator,
}) => {
  return (
    <ProCard className="px-2.5 mb-2" bordered>
      <Row gutter={[24, 12]}>
        <Col xs={24} sm={12} md={8}>
          <Input.Search
            placeholder="Số điện thoại, Họ và tên "
            value={search}
            onChange={onChangeText}
            allowClear
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          {isUserHaveOneOfPermissions([
            ROLES.ADMIN,
            ROLES.AM_LEADER,
            ROLES.AM,
          ]) && (
            <Select
              className="w-full"
              allowClear
              filterOption={false}
              showSearch
              placeholder="Mã đại lý"
              options={agents?.map(item => ({
                value: item,
                label: item,
              }))}
              onChange={onSelectAgent}
            />
          )}
        </Col>
        {isUserHaveOneOfPermissions([
          ROLES.ADMIN,
          ROLES.AM_LEADER,
          ROLES.AM,
        ]) && (
          <>
            <Col xs={24} sm={12} md={8} className="flex justify-end">
              <Button
                key="button"
                icon={<PlusOutlined />}
                onClick={onCreateAccount}
                type="primary">
                Thêm Tài khoản Đại lý
              </Button>
            </Col>
          </>
        )}
        {isUserHaveOneOfPermissions([ROLES.AGENT]) && (
          <Col xs={24} sm={12} md={8} className="flex justify-end">
            <Button
              key="button"
              icon={<PlusOutlined />}
              onClick={onCreateAccountCollaborator}
              type="primary">
              Tạo Tài khoản CTV
            </Button>
          </Col>
        )}
      </Row>
    </ProCard>
  );
};

export default CollaboratorsFilter;
