export enum ACCESS_TOKEN {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
}

export enum LOCAL_STORAGE_KEY {
  ROLE = 'ROLE',
  USER_INFO = 'USER_INFO',
}

export enum CATEGORY_TYPE_IMAGE {
  IDENTITY = 'identity',
  BANK = 'bank',
  DOCUMENT = 'document',
}

export enum ROLES {
  ADMIN = 'ADMIN',
  AM_LEADER = 'AM_LEADER',
  COLLABORATOR = 'COLLABORATOR',
  AM = 'AM',
  AGENT = 'AGENT',
  ACT = 'ACCOUNTANT',
}

export enum PROVIDED_TYPE {
  PACK_DATA = 'data_pack',
  COURSE = 'course',
}

export enum MODAL_POST_STATUS {
  CREATE = 1,
  EDIT = 2,
}

export const IS_HIDE_AMOUNT = 'IS_HIDE_AMOUNT';

export const STATUS_POST = [
  {id: 0, label: 'Hiển thị', value: true},
  {id: 1, label: 'Không hiển thị', value: false},
];

export const TARGET_TYPES = [
  {label: 'Tất cả', value: 'all'},
  // {label: 'Gửi cho người dùng cụ thẻ', value: 'specific'},
  {label: 'Gửi cho tất cả người dùng trong 1 đại lý', value: 'specific_agent'},
  {
    label: 'Gửi cho tất cả cộng tác viên trong 1 đại lý',
    value: 'specific_collaborator',
  },
  {label: 'Gửi cho các đại lý', value: 'specific_person_in_charge'},
];

export const STATUS_NOTIFICATION = [
  {label: 'Chưa được gửi', value: 0},
  {label: 'Đã được gửi', value: 1},
];
