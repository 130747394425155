import {dateFormat} from '@/utils/constants';
import {Button, DatePicker, Row} from 'antd';
import moment from 'moment';
import React from 'react';

type Props = {
  rangeDate: ReportParams;
  onChangeRangeDate: (values: any) => void;
  onSelectWeek: () => void;
  onSelectMonth: () => void;
};

export const ReportRevenueFilter: React.FC<Props> = ({
  rangeDate,
  onChangeRangeDate,
  onSelectWeek,
  onSelectMonth,
}) => {
  const {RangePicker} = DatePicker;

  const isActive = (time: 'week' | 'month') =>
    rangeDate.fromDate && rangeDate.toDate
      ? moment(rangeDate.fromDate.valueOf()).format(dateFormat) ==
          moment().startOf(time).format(dateFormat) &&
        moment(rangeDate.toDate.valueOf()).format(dateFormat) ==
          moment().format(dateFormat)
      : false;

  return (
    <div className="flex">
      <Row className="w-full">
        <Button
          type="default"
          className={`mr-2 ${isActive('week') ? 'active' : 'default'}`}
          onClick={onSelectWeek}
        >
          Tuần này
        </Button>
        <Button
          type="default"
          className={`mr-2 max-md:mb-3 ${
            isActive('month') ? 'active' : 'default'
          }`}
          onClick={onSelectMonth}
        >
          Tháng này
        </Button>
        <RangePicker
          format={dateFormat}
          onChange={onChangeRangeDate}
          placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
          value={[rangeDate.fromDate, rangeDate.toDate]}
          className="max-md:w-[90vw]"
        />
      </Row>
    </div>
  );
};
