import {isUserHaveOneOfPermissions} from '@/utils';
import {ROLES} from '@/utils/enum';
import {formatPrice, isNullOrEmpty} from '@/utils/stringUtils';
import {DeleteOutlined} from '@ant-design/icons';
import {ProColumns} from '@ant-design/pro-components';
import {Button, Popover} from 'antd';
import moment from 'moment';

const getColumnsConfig = (
  actionRef?: any,
  cancelPayment?: (id: number) => void,
  loadingCancel?: boolean
) => {
  // const role = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);

  const getStatusColor = (
    num: number
  ):
    | {name: string; color: string; background: string; borderColor: string}
    | undefined => {
    switch (num) {
      case 0:
        return {
          name: 'Chờ xử lý',
          color: 'rgba(0,0,0,.88)',
          background: '#FAFAFA',
          borderColor: '#D9D9D9',
        };
      case 1:
        return {
          name: 'Đang xử lý',
          color: '#0958d9',
          background: '#e6f4ff',
          borderColor: '#91caff',
        };
      case 2:
        return {
          name: 'Đã chuyển khoản',
          color: '#d48806',
          background: '#fffbe6',
          borderColor: '#ffe58f',
        };
      case 3:
        return {
          name: 'Đã nhận tiền',
          color: '#389e0d',
          background: '#f6ffed',
          borderColor: '#b7eb8f',
        };
      case 4:
        return {
          name: 'Đã huỷ',
          color: '#ff4d4f',
          background: '#fff2f0',
          borderColor: '#ffccc7',
        };
      default:
        break;
    }
  };

  const columns: ProColumns<PaymentItemInterface>[] = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 80,
      render: (_, __, indx) => {
        const current = actionRef?.current?.pageInfo?.current || 1;
        const size = actionRef?.current?.pageInfo?.pageSize || 10;
        return (current - 1) * size + indx + 1;
      },
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      width: 200,
    },
    {
      title: 'Mã yêu cầu',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      width: 160,
    },
    {
      title: 'Thời gian yêu cầu',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      render: (_, record) => {
        return moment(record.createdDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Thời gian xử lý',
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      align: 'center',
      render: (_, record) => {
        return moment(record.modifiedDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Số tiền yêu cầu',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      width: 200,
      render: (_, record) => {
        return !isNullOrEmpty(record?.amount)
          ? `${formatPrice(record?.amount)} VNĐ`
          : `0 VNĐ`;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      // width: 165,
      render: (_, record) => {
        const obj = getStatusColor(record?.status ?? 6);
        return (
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
            }}>
            <div
              style={{
                background: obj?.background,
                outlineColor: obj?.borderColor,
                alignContent: 'center',
              }}
              className={'statusContainer'}>
              <p
                className="txtStatus"
                style={{
                  color: obj?.color,
                }}>
                {obj?.name}
              </p>
            </div>
            {record?.status === 0 &&
              isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR]) && (
                <Popover
                  placement="bottom"
                  content={
                    <Button
                      onClick={cancelPayment?.bind(null, record.id)}
                      loading={loadingCancel}>
                      <p>Huỷ</p>
                    </Button>
                  }
                  trigger="click">
                  <DeleteOutlined style={{marginLeft: 10}} />
                </Popover>
              )}
          </div>
        );
      },
    },
  ];

  return columns;
};

export default getColumnsConfig;
