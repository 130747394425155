import {CreateModalRef} from '@/containers/Products/ModalCreate';
import {DetailModalRef} from '@/containers/Products/ModalDetail';
import {RESPONSE_CODE} from '@/network/constants';
import {productService} from '@/network/services/products';
import {isUserHaveOneOfPermissions} from '@/utils';
import {ROLES} from '@/utils/enum';
import {ActionType} from '@ant-design/pro-components';
import {Modal, message, notification} from 'antd';
// import {debounce} from 'lodash';
import React from 'react';

type State = {
  products: ProductItemInterface[];
  metadata: MetadataInterface | undefined;
  suppliers: SuppliersInterface[];
  isRefresh: boolean;
  isEdit: boolean;
  searchKeyword: string;
  status?: number;
  filters: FiterListProductInterface;
  isInitialRender: boolean;
  listCourse: Course[];
  supplierCode: string;
};

export const useProducts = () => {
  const [stateProducts, setStateProducts] = React.useState<State>({
    products: [],
    metadata: undefined,
    suppliers: [],
    isRefresh: false,
    isEdit: false,
    searchKeyword: '',
    filters: {
      size: 50,
      page: 0,
    },
    isInitialRender: true,
    listCourse: [],
    supplierCode: '',
  });

  const actionRef = React.useRef<ActionType>();
  const createRef = React.useRef<CreateModalRef>(null);
  const detailRef = React.useRef<DetailModalRef>(null);

  const [modalConfirm, contextHolder] = Modal.useModal();

  const hideProduct = (id: number, isHide: 0 | 1) => {
    const text = isHide ? 'Ẩn' : 'Hiện';
    modalConfirm.confirm({
      title: text + ' sản phẩm',
      content: 'Bạn có muốn ' + text + ' sản phẩm?',
      okText: 'Xác nhận',
      width: 350,
      onOk: async () => {
        const response = await productService.updateProduct(
          {
            status: isHide,
          },
          id
        );
        if (response?.status === RESPONSE_CODE.SUCCESS) {
          message.success('Đã ' + text + ' sản phẩm');
          setStateProducts(prevState => ({
            ...prevState,
            isRefresh: true,
          }));
        } else {
          message.success(response?.data?.message);
        }
      },
    });
  };

  React.useEffect(() => {
    getListProduct();
    getListSupplier();
    if (isUserHaveOneOfPermissions([ROLES.ADMIN, ROLES.AM_LEADER])) {
      getListCourse();
    }
  }, []);

  React.useEffect(() => {
    if (stateProducts?.isRefresh) {
      getListProduct();
    }
  }, [stateProducts?.isRefresh]);

  React.useEffect(() => {
    if (stateProducts?.isInitialRender) {
      setStateProducts(prevState => ({
        ...prevState,
        isInitialRender: false,
      }));
      return;
    }
    getListProduct();
    // delayedSearch();
    // return delayedSearch.cancel;
  }, [
    stateProducts?.searchKeyword,
    stateProducts?.status,
    stateProducts?.supplierCode,
  ]);

  // const delayedSearch = React.useCallback(
  //   debounce(() => onSearchData(), 300),
  //   [
  //     stateProducts?.searchKeyword,
  //     stateProducts?.status,
  //     stateProducts?.supplierCode,
  //   ]
  // );

  // const onSearchData = debounce(() => {
  //   getListProduct();
  // });

  const handleEditProduct: (record: any) => void = record => {
    setStateProducts(prevState => ({
      ...prevState,
      isEdit: true,
    }));
    createRef.current?.open(record);
  };

  const handleAddProduct = () => {
    setStateProducts(prevState => ({
      ...prevState,
      isEdit: false,
    }));
    createRef.current?.open();
  };

  const getListProduct = async () => {
    const params = {
      ...stateProducts?.filters,
      productName: stateProducts?.searchKeyword,
      status: stateProducts?.status,
      supplierCode: stateProducts?.supplierCode,
    };
    const res = await productService.getListProduct(params);
    const {data, page, size, total, totalPages} = res?.data;
    if (res.status === RESPONSE_CODE.SUCCESS) {
      setStateProducts(prevState => ({
        ...prevState,
        products: data,
        isRefresh: false,
        metadata: {
          totalPages: totalPages,
          page: page,
          size: size,
          total: total,
        },
      }));
    } else {
      setStateProducts(prevState => ({
        ...prevState,
        isRefresh: false,
      }));
    }
  };

  const getListSupplier = async () => {
    const res = await productService.listSupplier();
    const {data} = res?.data;
    if (res.status === RESPONSE_CODE.SUCCESS) {
      setStateProducts(prevState => ({
        ...prevState,
        suppliers: data,
      }));
    }
  };

  const getListCourse = async () => {
    const res = await productService.listCourse();
    const {content} = res?.data;
    if (res.status === RESPONSE_CODE.SUCCESS) {
      setStateProducts(prevState => ({
        ...prevState,
        listCourse: content,
      }));
    }
  };

  const createProduct = async (
    params: ProductParamsInterface,
    onReload?: () => void
  ) => {
    const res = await productService.createProduct(params);
    if (res.status === RESPONSE_CODE.SUCCESS) {
      onReload?.();
      setStateProducts(prevState => ({
        ...prevState,
        isRefresh: true,
      }));
      notification.success({message: 'Thêm sản phẩm thành công !'});
    } else {
      message.error(res?.data);
    }
  };

  const updateProduct = async (
    params: ProductParamsInterface,
    id: number = 0,
    onReload?: () => void
  ) => {
    const res = await productService.updateProduct(params, id);
    if (res.status === RESPONSE_CODE.SUCCESS) {
      onReload?.();
      setStateProducts(prevState => ({
        ...prevState,
        isRefresh: true,
      }));
      notification.success({message: 'Sửa sản phẩm thành công !'});
    } else {
      message.error(res?.data);
    }
  };

  const handleSearch = (value: string) => {
    setStateProducts(prevState => ({
      ...prevState,
      searchKeyword: value,
      filters: {
        ...prevState.filters,
        page: 0,
      },
    }));
  };

  const handleStatus = (value: number) => {
    setStateProducts(prevState => ({
      ...prevState,
      status: value,
      filters: {
        ...prevState.filters,
        page: 0,
      },
    }));
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setStateProducts(prevState => ({
      ...prevState,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const onFilterProvided = (value: string) => {
    setStateProducts(prevState => ({
      ...prevState,
      supplierCode: value,
      filters: {
        ...prevState.filters,
        page: 0,
      },
    }));
  };

  const handleCreateLink = async (id: number) => {
    const res = await productService?.createAffiliatelink(id);
    if (res.status === RESPONSE_CODE.SUCCESS) {
      message.success('Tạo link thành công !');
      setStateProducts(prevState => ({
        ...prevState,
        isRefresh: true,
      }));
    } else {
      message.error('Tạo link thất bại !');
    }
  };

  const handleDetailProduct: (record: any) => void = record => {
    detailRef?.current?.open(record);
  };

  return {
    stateProducts,
    createProduct,
    updateProduct,
    actionRef,
    createRef,
    detailRef,
    handleEditProduct,
    handleDetailProduct,
    handleAddProduct,
    handleSearch,
    handleStatus,
    contextHolder,
    hideProduct,
    onPageChange,
    onFilterProvided,
    handleCreateLink,
  };
};
