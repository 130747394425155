import {formatPrice} from '@/utils/stringUtils';
import {Drawer, Table} from 'antd';
import React, {Ref, useImperativeHandle} from 'react';

export type ReportCampaignDetailModalRef = {
  open: (data: ReportCampaignItem[]) => void;
};

const ReportCampaignDetail = (
  _: any,
  ref: Ref<ReportCampaignDetailModalRef>
) => {
  const [open, setOpen] = React.useState(false);
  const [dataDetails, setDataDetails] = React.useState<ReportCampaignItem[]>(
    []
  );

  const onclose = () => {
    setOpen(false);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: data => {
          setDataDetails(data);
          setOpen(true);
        },
      };
    },
    []
  );

  const columns: any[] = [
    {
      title: 'Tên chiến dịch',
      dataIndex: 'campaignName',
      align: 'center',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'productName',
      align: 'center',
    },
    {
      title: 'Tổng số',
      dataIndex: 'totalOrder',
      key: 'totalOrder',
      align: 'center',
      width: '10%',
    },
    {
      title: 'Số lượng',
      dataIndex: 'totalQuantity',
      key: 'totalQuantity',
      align: 'center',
    },
    {
      title: 'Giá bán',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      align: 'center',
      render: (value: number) => formatPrice(value) + ' VNĐ',
    },
    {
      title: 'Doanh thu đại lý',
      dataIndex: 'totalAgentRevenue',
      key: 'totalAgentRevenue',
      align: 'center',
      render: (value: number) => formatPrice(value) + ' VNĐ',
    },
    {
      title: 'Hoa hồng thực nhận',
      dataIndex: 'totalCollaboratorRevenue',
      key: 'totalCollaboratorRevenue',
      align: 'center',
      render: (value: number) => formatPrice(value) + ' VNĐ',
    },
  ];

  return (
    <Drawer
      title={'Thông tin chi tiết'}
      open={open}
      width={1200}
      onClose={onclose}
      destroyOnClose={true}>
      <Table dataSource={dataDetails} columns={columns} />
    </Drawer>
  );
};
export const ReportCampaignDetailModal = React.forwardRef(ReportCampaignDetail);
