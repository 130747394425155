import {RESPONSE_CODE} from '@/network/constants';
import {listBank} from '@/network/services/accounts';
import {authServices} from '@/network/services/auth';
import {message} from 'antd';
import {RcFile} from 'antd/es/upload';
import dayjs, {Dayjs} from 'dayjs';
import {isArray} from 'lodash';
import {ROUTERS} from './constants';
import {LOCAL_STORAGE_KEY} from './enum';
import {isNullOrEmpty} from './stringUtils';
import moment from 'moment';

export const validateNumber = (_rule: any, value: any, callback: any) => {
  if (isNaN(value) || value <= 0) {
    callback('Vui lòng nhập số nguyên dương');
  } else {
    callback();
  }
};

export const validateNumberPrice = (_rule: any, value: any, callback: any) => {
  const numericValue = parseFloat(value.replace(/,/g, '').replace(/\./g, ''));
  if (isNaN(numericValue) || numericValue <= 0) {
    callback('Vui lòng nhập số nguyên dương');
  } else {
    callback();
  }
};

export const numericValue = (value: string) => {
  return parseFloat(value.replace(/,/g, '').replace(/\./g, ''));
};

export const validateAgentCommissionFee = (
  rule: any,
  value: any,
  callback: any
) => {
  if (isNullOrEmpty(value) || isNaN(value)) {
    callback('Vui lòng nhập phần trăm hoa hồng !');
  } else if (value > 100) {
    callback('Phần trăm hoa hồng phải nhỏ hơn hoặc bằng 100%');
  } else {
    callback();
  }
};

export const validateCollaboratorCommissionFee = (
  rule: any,
  value: any,
  callback: any
) => {
  if (isNullOrEmpty(value) || isNaN(value)) {
    callback('Vui lòng nhập % hoa hồng !');
  } else if (value > 100 || value <= 0) {
    callback('% hoa hồng phải lớn hơn 0 và nhỏ hơn 100%');
  } else {
    callback();
  }
};

export const validateWhitespace = (rule: any, value: any, callback: any) => {
  if (value && value.trim() === '') {
    callback('Không được nhập khoảng trắng!');
  } else {
    callback();
  }
};

export const formatted = (value: string) => {
  return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const checkVideoBeforeUpload = (file: RcFile) => {
  const isCheckTypeVideo = file.type === 'video/mp4';

  return isCheckTypeVideo;
};

export const checkImageBeforeUpload = (file: RcFile) => {
  const isCheckTypeImage =
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/webp' ||
    file.type === 'image/jpeg';
  if (!isCheckTypeImage) {
    message.error('Bạn chỉ có thể tải lên tệp JPG / JPEG / PNG ? WEBP!');
  }
  const isLt10M = file.size / 1024 / 1024 < 5;
  if (!isLt10M) {
    message.error('Hình ảnh phải nhỏ hơn 5MB!');
  }
  return isCheckTypeImage && isLt10M;
};

export const getListBank = async () => {
  let data = [];
  const list = localStorage.getItem('listBank');
  if (list) {
    return JSON.parse(list);
  } else {
    try {
      const res = await listBank();
      if (res && isArray(res.data)) {
        data = res.data.map((item: any) => ({
          label: item.shortName,
          value: item.shortName,
        }));
        localStorage.setItem('listBank', JSON.stringify(data));
      }
      return data;
    } catch (error) {
      return [];
    }
  }
};

export const callUserInformation = async () => {
  const res = await authServices.getUser();
  const {role} = res.data;
  if (res?.status === RESPONSE_CODE.SUCCESS) {
    localStorage.setItem(LOCAL_STORAGE_KEY.ROLE, role);
    localStorage.setItem(LOCAL_STORAGE_KEY.USER_INFO, JSON.stringify(res.data));
    window.location.href = ROUTERS.Orders.path;
  }
};

export const disabledDate = (current: Dayjs | null) => {
  return current ? current.isBefore(dayjs().startOf('day')) : false;
};
export const getDisabledHours = (date?: string) => {
  const hours = [];
  if (dayjs().isSame(dayjs(date, 'DD/MM/YYYY'), 'day')) {
    for (let i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
  }
  return hours;
};
