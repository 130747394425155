import {CampaignDetailModalRef} from '@/containers/Campaigns/CampaignDetail';
import {ConfigModalRef} from '@/containers/Campaigns/ModalConfig';
import {CreateModalRef} from '@/containers/Campaigns/ModalCreate';
import {RESPONSE_CODE} from '@/network/constants';
import accountsServices from '@/network/services/accounts';
import {campaignServices} from '@/network/services/campaigns';
import {isUserHaveOneOfPermissions} from '@/utils';
import {DEFAULT_FORMAT_DATE} from '@/utils/constants';
import {ROLES} from '@/utils/enum';
import {isNullOrEmpty} from '@/utils/stringUtils';
import {ActionType} from '@ant-design/pro-components';
import {Modal, message} from 'antd';
import {debounce} from 'lodash';
import moment from 'moment';
import React from 'react';

type State = {
  data: CampaignItemInterface[];
  filters: FilterListInterface;
  metadata: MetadataInterface | undefined;
  isRefresh: boolean;
  searchKeyword: string;
  datesStart: string[];
  datesEnd: string[];
  isInitialRender: boolean;
  isEdit: boolean;
  listAgent: AgentInterface[];
  listCollaborators: AccountType[];
  type: string;
  loading: boolean;
};
export const useCampaign = () => {
  const [stateCampaign, setStateCampaign] = React.useState<State>({
    data: [],
    filters: {
      size: 50,
      page: 0,
    },
    metadata: undefined,
    isRefresh: false,
    searchKeyword: '',
    datesStart: [],
    datesEnd: [],
    isInitialRender: true,
    isEdit: false,
    listAgent: [],
    listCollaborators: [],
    type: '',
    loading: false,
  });

  const actionRef = React.useRef<ActionType>();
  const createRef = React.useRef<CreateModalRef>(null);
  const campaignDetailModalRef = React.useRef<CampaignDetailModalRef>(null);
  const configModalRef = React.useRef<ConfigModalRef>(null);
  const [modalConfirm, contextHolder] = Modal.useModal();

  React.useEffect(() => {
    getListCampaign();
    getListCollaborators();
  }, []);

  React.useEffect(() => {
    if (stateCampaign?.type !== '') {
      if (
        !isUserHaveOneOfPermissions([ROLES.COLLABORATOR, ROLES.AM, ROLES.AGENT])
      ) {
        getListAgent();
      }
    }
  }, [stateCampaign?.type]);

  React.useEffect(() => {
    if (stateCampaign.isRefresh) {
      getListCampaign();
    }
  }, [stateCampaign.isRefresh]);

  React.useEffect(() => {
    if (stateCampaign?.isInitialRender) {
      setStateCampaign(prevState => ({
        ...prevState,
        isInitialRender: false,
      }));
      return;
    }
    delayedSearch();
    return delayedSearch.cancel;
  }, [
    stateCampaign.searchKeyword,
    stateCampaign.datesStart,
    stateCampaign.datesEnd,
  ]);

  const delayedSearch = React.useCallback(
    debounce(() => onSearchData(), 300),
    [
      stateCampaign.searchKeyword,
      stateCampaign.datesStart,
      stateCampaign.datesEnd,
    ]
  );

  const onSearchData = debounce(() => {
    getListCampaign();
  });

  const getListCampaign = async () => {
    const params = {
      ...stateCampaign.filters,
      query: stateCampaign.searchKeyword,
      fromStartTime: !isNullOrEmpty(stateCampaign.datesStart[0])
        ? moment(stateCampaign.datesStart[0])
            .startOf('day')
            .format(DEFAULT_FORMAT_DATE)
        : undefined,
      toStartTime: !isNullOrEmpty(stateCampaign.datesStart[1])
        ? moment(stateCampaign.datesStart[1])
            .endOf('day')
            .format(DEFAULT_FORMAT_DATE)
        : undefined,
      fromEndTime: !isNullOrEmpty(stateCampaign.datesEnd[0])
        ? moment(stateCampaign.datesEnd[0])
            .startOf('day')
            .format(DEFAULT_FORMAT_DATE)
        : undefined,
      toEndTime: !isNullOrEmpty(stateCampaign.datesEnd[1])
        ? moment(stateCampaign.datesEnd[1])
            .endOf('day')
            .format(DEFAULT_FORMAT_DATE)
        : undefined,
    };
    const res = await campaignServices.campaigns(params);

    const {data, page, size, total, totalPages} = res?.data;
    if (res.status === RESPONSE_CODE.SUCCESS) {
      setStateCampaign(prevState => ({
        ...prevState,
        data: data,
        isRefresh: false,
        metadata: {
          totalPages: totalPages,
          page: page,
          size: size,
          total: total,
        },
      }));
    } else {
      setStateCampaign(prevState => ({
        ...prevState,
        isRefresh: false,
      }));
    }
  };

  const getListAgent = async () => {
    const res = await campaignServices.listAgent(stateCampaign?.type);
    setStateCampaign(prevState => ({
      ...prevState,
      listAgent: res.data,
    }));
  };

  const createCampaign = async (
    params: CampaignParamInterface,
    onLoad?: () => void
  ) => {
    const res = await campaignServices.createCampaign(params);
    if (res.status === RESPONSE_CODE.SUCCESS) {
      onLoad?.();
      setStateCampaign(prevState => ({
        ...prevState,
        isRefresh: true,
      }));
      message.success('Thêm chiến dịch thành công !');
    } else {
      message.error(res?.data);
    }
  };

  const updateCampaign = async (
    params: CampaignParamInterface,
    id: number = 0,
    onLoad?: () => void
  ) => {
    const res = await campaignServices.updateCampaign(params, id);
    if (res.status === RESPONSE_CODE.SUCCESS) {
      onLoad?.();
      setStateCampaign(prevState => ({
        ...prevState,
        isRefresh: true,
      }));
      message.success('Cập nhật chiến dịch thành công !');
    } else {
      message.error(res?.data);
    }
  };

  const handleAddCampaign = () => {
    setStateCampaign(prevState => ({
      ...prevState,
      isEdit: false,
    }));
    createRef.current?.open();
  };

  const handleEditCampaign: (record: any) => void = record => {
    setStateCampaign(prevState => ({
      ...prevState,
      isEdit: true,
    }));
    createRef.current?.open(record);
  };

  const handleSearch = (value: string) => {
    setStateCampaign(prevState => ({
      ...prevState,
      searchKeyword: value,
      filters: {
        ...prevState.filters,
        page: 0,
      },
    }));
  };

  const handleSearchStartDate = (value: string[]) => {
    setStateCampaign(prevState => ({
      ...prevState,
      datesStart: value,
      filters: {
        ...prevState.filters,
        page: 0,
      },
    }));
  };

  const handleSearchEndDate = (value: string[]) => {
    setStateCampaign(prevState => ({
      ...prevState,
      datesEnd: value,
      filters: {
        ...prevState.filters,
        page: 0,
      },
    }));
  };

  const hiddenCampaign = (id: number, isHide: 0 | 1) => {
    const text = isHide ? 'Ẩn' : 'Hiện';
    modalConfirm.confirm({
      title: text + ' chiến dịch',
      content: 'Bạn có muốn ' + text + ' chiến dịch?',
      okText: 'Xác nhận',
      width: 350,
      onOk: async () => {
        const response = await campaignServices.hiddenCampaign(id, isHide);
        if (response?.status === RESPONSE_CODE.SUCCESS) {
          message.success('Đã ' + text + ' chiến dịch');
          setStateCampaign(prevState => ({
            ...prevState,
            isRefresh: true,
          }));
        } else {
          message.error(response?.data?.message);
        }
      },
    });
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setStateCampaign(prevState => ({
      ...prevState,
      isRefresh: true,
      filters: {
        ...prevState.filters,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const onFilterAgent = (value: string) => {
    setStateCampaign(prevState => ({
      ...prevState,
      type: value,
    }));
  };

  const handleDetailCampaign: (
    record: ListCampaignInterface
  ) => void = record => {
    campaignDetailModalRef.current?.open(record);
  };

  const getListCollaborators = async () => {
    let params = {
      page: 0,
      size: 1000,
      role: 'COLLABORATOR',
    };
    const response = await accountsServices.collaborators(params);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      const {data} = response?.data;
      setStateCampaign(prevState => ({
        ...prevState,
        listCollaborators: data,
      }));
    }
  };

  const handleConfig = async (id: number) => {
    setStateCampaign(prevState => ({
      ...prevState,
      loading: true,
    }));
    const response = await campaignServices.getConfigShareValue(id);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      configModalRef?.current?.open(response?.data);
      setStateCampaign(prevState => ({
        ...prevState,
        loading: false,
      }));
    } else {
      message.error('Có lỗi xảy ra!!');
      setStateCampaign(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const createConfigShareValue = async (params: ConfigShareValue) => {
    const response = await campaignServices.createConfigShareValue(params);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      message.success('Cập nhật thành công!!');
      configModalRef?.current?.close();
    } else {
      message.error('Cập nhật thất bại!!');
    }
  };

  return {
    stateCampaign,
    createCampaign,
    updateCampaign,
    handleSearch,
    handleSearchStartDate,
    handleSearchEndDate,
    actionRef,
    createRef,
    campaignDetailModalRef,
    configModalRef,
    handleAddCampaign,
    handleEditCampaign,
    hiddenCampaign,
    contextHolder,
    onPageChange,
    onFilterAgent,
    handleDetailCampaign,
    handleConfig,
    createConfigShareValue,
  };
};
