import {APPLICATION_ACTION_TYPE} from '@/context/action';
import useQuery from '@/hooks/useQuery';
import {RESPONSE_CODE} from '@/network/constants';
import {register, verifyOtp} from '@/network/services/auth';
import {ACCESS_TOKEN} from '@/utils/enum';
import {callUserInformation} from '@/utils/helper';
import {LoginFormPage, ProCard} from '@ant-design/pro-components';
import {message} from 'antd';
import React, {useRef, useState} from 'react';
import RequestOTP from './components/RequestOTP';
import SingUp from './components/SingUp';
import './styles.scss';

const defaultInformation = {
  textBtn: 'Gửi OTP',
  hasSentOTP: false,
};

const verifyInformation = {
  textBtn: 'Xác thực OTP',
  hasSentOTP: true,
};

export const Register: React.FC = () => {
  const [information, setInformation] = useState<{
    textBtn: string;
    hasSentOTP: boolean;
  }>(defaultInformation);
  const informationRef = useRef({phoneNumber: ''});
  const [loading, setLoading] = useState<boolean>(false);
  let query = useQuery();

  const handleSingUp = async (values: SingUpType) => {
    let agencyCode: string | null = query.get('agentCode');
    const res = await register({
      ...values,
      agencyCode,
    });
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setInformation(verifyInformation);
      informationRef.current = {
        ...values,
      };
    } else {
      message.error(res?.data);
    }
  };

  const handleVerifyOtp = async (values: SendOtpType) => {
    if (values.otp.length < 6) {
      message.error('Bạn đã nhập sai OTP hoặc OTP đã hết hạn');
    } else {
      const res = await verifyOtp({...values, ...informationRef.current});
      if (res?.status === RESPONSE_CODE.SUCCESS) {
        localStorage.setItem(
          APPLICATION_ACTION_TYPE.NEED_UPDATE_COLLAB_INFO,
          'true'
        );
        const {access_token} = res?.data;
        localStorage.setItem(ACCESS_TOKEN.ACCESS_TOKEN, access_token);
        callUserInformation();
      } else {
        message.error(res?.data);
      }
    }
  };

  return (
    <ProCard
      style={{
        backgroundColor: 'white',
        height: 'calc(100vh - 20px)',
      }}>
      <LoginFormPage
        backgroundImageUrl="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
        logo="https://seekicon.com/free-icon-download/octocat_1.png"
        title="PRM"
        subTitle={<div></div>}
        submitter={{
          searchConfig: {
            submitText: information.textBtn,
          },
          submitButtonProps: {loading},
        }}
        onFinish={async values => {
          setLoading(true);
          if (information.hasSentOTP) {
            await handleVerifyOtp(values as SendOtpType);
          } else {
            await handleSingUp(values as SingUpType);
          }
          setLoading(false);
        }}>
        <>
          {information.hasSentOTP ? (
            <RequestOTP phone={informationRef.current?.phoneNumber} />
          ) : (
            <SingUp />
          )}
        </>
      </LoginFormPage>
    </ProCard>
  );
};
