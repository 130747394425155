import {FC} from 'react';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {LoginFormPage, ProCard, ProFormText} from '@ant-design/pro-components';
import {notification} from 'antd';
import {useState} from 'react';
import accountsServices from '@/network/services/accounts';
import {RESPONSE_CODE} from '@/network/constants';
import {callUserInformation} from '@/utils/helper';
import {ACCESS_TOKEN} from '@/utils/enum';
import {ruleValidatePassword} from '@/utils/constants';
import Link from 'antd/es/typography/Link';

const ChangePassword: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const onChangePassword = async (values: ChangePasswordForm) => {
    setLoading(true);
    if (values?.newPassword === values?.oldPassword) {
      notification.error({
        message: 'Mật khẩu mới không được trùng với mật khẩu cũ',
      });
      setLoading(false);
      return;
    }
    const response = await accountsServices.changePassword(values);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      const {access_token} = response?.data;
      callUserInformation();
      localStorage.setItem(ACCESS_TOKEN.ACCESS_TOKEN, access_token);
    } else {
      notification.error({
        message: response?.data as unknown as string,
      });
    }
    setLoading(false);
  };

  return (
    <ProCard className="bg-white h-screen">
      <LoginFormPage
        backgroundImageUrl="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
        logo="https://github.githubassets.com/images/modules/logos_page/Octocat.png"
        title="PRM"
        subTitle={<div>Đổi mật khẩu</div>}
        submitter={{
          searchConfig: {
            submitText: 'Đổi mật khẩu',
          },
          submitButtonProps: {loading},
        }}
        onFinish={onChangePassword}>
        <ProFormText
          name="username"
          fieldProps={{
            size: 'large',
            prefix: <UserOutlined className={'prefixIcon'} />,
          }}
          placeholder="Tên đăng nhập/Số điện thoại"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập Tên tài khoản!',
            },
          ]}
        />
        <ProFormText.Password
          name="oldPassword"
          fieldProps={{
            size: 'large',
            prefix: <LockOutlined className={'prefixIcon'} />,
          }}
          placeholder={'Mật khẩu hiện tại'}
          rules={[
            {required: true, message: 'Vui lòng nhập Mật khẩu hiện tại!'},
          ]}
        />
        <ProFormText.Password
          name="newPassword"
          fieldProps={{
            size: 'large',
            prefix: <LockOutlined className={'prefixIcon'} />,
          }}
          placeholder={'Mật khẩu mới'}
          rules={[
            {required: true, message: 'Vui lòng nhập Mật khẩu mới!'},
            ruleValidatePassword,
          ]}
        />
        <ProFormText.Password
          name="confirmNewPassword"
          fieldProps={{
            size: 'large',
            prefix: <LockOutlined className={'prefixIcon'} />,
          }}
          placeholder={'Nhập lại mật khẩu mới'}
          rules={[
            {required: true, message: 'Vui lòng nhập lại mật khẩu mới!'},
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Mật khẩu xác nhận không khớp!');
              },
            }),
          ]}
        />
        <div className="flex justify-end mb-1">
          <Link href={'/'}>Đăng nhập</Link>
        </div>
      </LoginFormPage>
    </ProCard>
  );
};

export default ChangePassword;
