import {Register} from '@/containers/Search/Register';
import {APPLICATION_ACTION_TYPE} from '@/context/action';
import AppContext from '@/context/appContext';
import useCheckMobileScreen from '@/hooks/useCheckMobileScreen';
import {useRegisterPackage} from '@/hooks/useRegisterPackage';
import {getInfoUser} from '@/network/services/accounts';
import {isRoleAgentCtv, isUserHaveOneOfPermissions, onLogout} from '@/utils';
import {ROUTERS} from '@/utils/constants';
import {IS_HIDE_AMOUNT, ROLES} from '@/utils/enum';
import {formatPrice} from '@/utils/stringUtils';
import {
  BellOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  LogoutOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {ProConfigProvider, ProLayout} from '@ant-design/pro-components';
import {HeaderViewProps} from '@ant-design/pro-layout/es/components/Header';
import {SiderMenuProps} from '@ant-design/pro-layout/es/components/SiderMenu/SiderMenu';
import {WithFalse} from '@ant-design/pro-layout/es/typing';
import {AvatarProps, Button, ConfigProvider, Dropdown, Popover} from 'antd';
import React, {useContext, useEffect, useRef} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import defaultProps from './_defaultProps';
import {Menu} from './menu';
import './styles.scss';
import {CreateCollaboratorModal} from '@/containers/Collaborators/CreateCollaboratorModal';
import {useWindowSize} from '@/hooks/useWindowSize';
import {MyNotificationModal} from '@/containers/MyNotification';

type AvatarPropsType = WithFalse<
  AvatarProps & {
    title?: React.ReactNode;
    render?: (
      props: AvatarProps,
      defaultDom: React.ReactNode
    ) => React.ReactNode;
  }
>;

export const Layout = () => {
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const {state, onOpenRegister} = useRegisterPackage();
  let navigate = useNavigate();
  const {dispatch, appState} = useContext(AppContext);
  const isMobile = useCheckMobileScreen();
  const windowSize = useWindowSize();

  const modalCreateCollabRef = useRef<any>(null);
  const myNotificationModal = useRef<any>(null);

  const isNeedUpdateCollab = localStorage.getItem(
    APPLICATION_ACTION_TYPE.NEED_UPDATE_COLLAB_INFO
  );

  useEffect(() => {
    if (isNeedUpdateCollab === 'true') {
      modalCreateCollabRef.current?.onOpenModal();
    }
  }, [isNeedUpdateCollab]);

  const getInfo = async () => {
    const info = await getInfoUser();
    if (info) {
      dispatch({
        type: APPLICATION_ACTION_TYPE.UPDATE_USER_INFOR,
        payload: info,
      });
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  const personalMenu = [
    {
      key: 'profile',
      icon: <UserOutlined />,
      label: 'Trang cá nhân',
      onClick: () => {
        navigate(ROUTERS.Profile.path);
      },
      hidden: !isRoleAgentCtv(),
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Đăng xuất',
      onClick: onLogout,
    },
  ].filter(item => !item.hidden);

  if (typeof document === 'undefined') {
    return <div />;
  }

  const onClickHideAmount = () => {
    localStorage.setItem(IS_HIDE_AMOUNT, `${!appState?.isHideAmount}`);
    dispatch({
      type: APPLICATION_ACTION_TYPE.SET_IS_HIDE_AMOUNT,
      payload: null,
    });
  };

  const avatarProps: AvatarPropsType = {
    src: 'https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg',
    size: 'small',
    render: (_, dom) => {
      return (
        <Dropdown
          trigger={['click']}
          menu={{
            items: personalMenu,
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: -10,
            }}>
            {dom}
          </div>
        </Dropdown>
      );
    },
  };

  const headerTitleRender: WithFalse<
    (
      logo: React.ReactNode,
      title: React.ReactNode,
      props: HeaderViewProps
    ) => React.ReactNode
  > = (logo, __, props) => {
    const defaultDom = (
      <a>
        {logo}
        {!collapsed && <h1>PRM</h1>}
      </a>
    );
    if (typeof window === 'undefined') return defaultDom;
    if (document.body.clientWidth < 1400) {
      return defaultDom;
    }
    if (props!.isMobile) return defaultDom;
    return <>{defaultDom}</>;
  };

  const renderHeader = () => {
    return (
      <div className="headerContainer">
        {isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR]) && (
          <Popover
            popupVisible={state?.isOpen}
            placement="bottom"
            content={<Register />}
            trigger="click">
            <Button
              key="button"
              icon={<PlusOutlined height={5} width={5} />}
              type="primary"
              onClick={onOpenRegister}>
              {isMobile ? 'Sản phẩm' : 'Danh sách sản phẩm'}
            </Button>
          </Popover>
        )}
        <div
          onClick={() => myNotificationModal?.current?.open()}
          className="ml-3 cursor-pointer">
          <BellOutlined />
        </div>
        {isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR]) && (
          <div
            className="balanceContainer"
            style={{
              marginLeft: 20,
              width: isMobile ? 105 : 140,
            }}>
            <div className="amountContainer">
              <p
                className="txtBalance"
                style={{
                  fontSize: isMobile ? 12 : 14,
                }}>
                Số dư khả dụng
              </p>
              <div className="amountField">
                {!appState?.isHideAmount ? (
                  <p
                    className="txtAmount"
                    style={{
                      fontSize: !appState?.isHideAmount && !isMobile ? 20 : 15,
                      marginTop: !isMobile ? 7 : 4,
                    }}>
                    *********
                  </p>
                ) : (
                  <p
                    className="txtAmount"
                    style={{
                      fontSize: isMobile ? 10 : 12,
                    }}>
                    {formatPrice(appState?.userInfor?.balanceAvailable) + 'đ'}
                  </p>
                )}
                <Button onClick={onClickHideAmount} className="btnHideAmount">
                  {appState?.isHideAmount ? (
                    <EyeInvisibleOutlined style={{color: '#1677FF'}} />
                  ) : (
                    <EyeOutlined style={{color: '#1677FF'}} />
                  )}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      id="test-pro-layout"
      style={{
        height: '100vh',
        overflow: 'hidden',
      }}>
      <ProConfigProvider hashed={false}>
        <ConfigProvider
          getTargetContainer={() => {
            return document.getElementById('test-pro-layout') || document.body;
          }}>
          <ProLayout
            prefixCls="my-prefix"
            layout={'mix'}
            fixSiderbar={false}
            siderMenuType="group"
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
            {...defaultProps}
            menu={{
              collapsedShowGroupTitle: true,
            }}
            avatarProps={avatarProps}
            headerTitleRender={headerTitleRender}
            menuContentRender={(props: SiderMenuProps) => (
              <Menu
                collapsed={collapsed}
                menuProps={props.menuData as any}
                clickMenu={isMobile ? setCollapsed?.bind(null, true) : () => {}}
              />
            )}
            headerContentRender={() => renderHeader()}
            contentStyle={{
              height: windowSize.height * 0.88,
              overflow: 'auto',
            }}
            menuItemRender={(item, dom) => (
              <div
                onClick={() => {
                  navigate(`${item.path}`);
                  isMobile && setCollapsed(true);
                }}>
                {dom}
              </div>
            )}>
            <Outlet />
            <CreateCollaboratorModal ref={modalCreateCollabRef} isNeedUpdate />
            <MyNotificationModal ref={myNotificationModal} />
          </ProLayout>
        </ConfigProvider>
      </ProConfigProvider>
    </div>
  );
};
