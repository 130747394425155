import {Row} from 'antd';

const header_data = [
  {
    name: 'KHU VỰC',
    styles:
      'w-[calc(30%)] items-center justify-center text-center text-[12px] font-bold',
  },
  {
    name: 'CHI NHÁNH',
    styles:
      'w-[calc(8%)] items-center justify-center text-center text-[12px] font-bold',
  },
  // {
  //   name: 'TK QUẢN LÝ',
  //   styles:
  //     'w-[calc(15%)] items-center justify-center text-center text-[12px] font-bold',
  // },
  {
    name: 'SL ĐẠI LÝ',
    styles:
      'w-[calc(21%)] items-center justify-center text-center text-[12px] font-bold',
  },
  {
    name: 'SL CTV',
    styles:
      'w-[calc(8%)] items-center justify-center text-center text-[12px] font-bold',
  },
  {
    name: 'CẬP NHẬT',
    styles:
      'w-[calc(20%)] items-center justify-center text-center text-[12px] font-bold',
  },
  {
    name: 'THAO TÁC',
    styles:
      'w-[calc(10%)] items-center justify-center text-center text-[12px] font-bold',
  },
];

export const Header = () => {
  return (
    <Row
      className={
        'bg-[#f2f2f2] pt-4 pb-4 border-solid border border-[#DDDDDD] rounded-t-md'
      }>
      {header_data?.map((item, index) => (
        <div key={index} className={item?.styles}>
          {item?.name}
        </div>
      ))}
    </Row>
  );
};
