import MyRoutes from './Routers';
import {Helmet} from 'react-helmet';
import {ConfigProvider} from 'antd';
import viVN from 'antd/es/locale/vi_VN';
import {ACCESS_TOKEN} from './utils/enum';
import {isNullOrEmpty} from './utils/stringUtils';
import moment from 'moment';
import './styles/App.css';
import './styles/main.scss';
import {AppProvider} from './context/appContext';

const locale = viVN;

function App() {
  moment.locale('vi', {
    week: {
      dow: 1,
    },
  });
  const accessToken = localStorage.getItem(ACCESS_TOKEN.ACCESS_TOKEN);
  return (
    <AppProvider>
      <Helmet encodeSpecialCharacters={true} titleTemplate="PRM - %s"></Helmet>
      <ConfigProvider locale={locale}>
        {MyRoutes(!isNullOrEmpty(accessToken))}
      </ConfigProvider>
    </AppProvider>
  );
}

export default App;
