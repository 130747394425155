import {dateFormat} from '@/utils/constants';
import {disabledDate, formatted, getDisabledHours} from '@/utils/helper';
import {formatPrice, isNullOrEmpty} from '@/utils/stringUtils';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {DrawerForm} from '@ant-design/pro-components';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  TimePicker,
  Typography,
  message,
} from 'antd';
import type {Dayjs} from 'dayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import React, {Ref, forwardRef, useImperativeHandle, useState} from 'react';

export type CreateModalRef = {
  open: (data?: CampaignItemInterface | undefined) => void;
};

enum TYPE {
  START = 'START',
  END = 'END',
}

export type Props = {
  isEdit?: boolean;
  product: ProductItemInterface[];
  updateCampaign: (
    params: CampaignParamInterface,
    id: number | undefined,
    onReload: () => void
  ) => void;
  createCampaign: (
    params: CampaignParamInterface,
    onReload: () => void
  ) => void;
  suppliers: SuppliersInterface[];
  agents: AgentInterface[];
  onChangeSelectProvided: (_values: string, options: any) => void;
  onFilterProvided: (code: string) => void;
  onFilterAgent: (type: string) => void;
};

const ModalCreate = (
  {
    isEdit,
    createCampaign,
    updateCampaign,
    product = [],
    suppliers,
    agents,
    onChangeSelectProvided,
    onFilterProvided,
    onFilterAgent,
  }: Props,
  ref: Ref<CreateModalRef>
) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [dataDetails, setDataDetails] = useState<
    CampaignItemInterface | undefined
  >();
  const [timeState, setTimeState] = useState<{
    start: string | dayjs.Dayjs;
    end: string | dayjs.Dayjs;
  }>({
    start: '',
    end: '',
  });
  const [dateState, setDateState] = useState<{
    start: string;
    end: string;
  }>({
    start: '',
    end: '',
  });

  const handleFillData = (data: CampaignItemInterface | undefined) => {
    if (isEdit) {
      console.log(
        dayjs(moment(data?.startTime).format(dateFormat), dateFormat)
      );

      form.setFieldsValue({
        name: data?.name,
        startTime: dayjs(
          moment(data?.startTime).format(dateFormat),
          dateFormat
        ),
        endTime: dayjs(moment(data?.endTime).format(dateFormat), dateFormat),
        productsMaxCommissionShare: data?.productsMaxCommissionShare?.map(
          item => ({
            productId: item.productId,
            maxCommissionShare: formatPrice(Number(item.maxCommissionShare)),
          })
        ),
        note: data?.note,
        supplierId: data?.supplier?.id,
        agents: data?.agents,
      });
      setTimeState(prevState => ({
        ...prevState,
        start: moment(data?.startTime).format('HH:mm:ss'),
        end: moment(data?.endTime).format('HH:mm:ss'),
      }));
      setDateState(prevState => ({
        ...prevState,
        start: moment(data?.startTime).format('DD/MM/YYYY'),
        end: moment(data?.endTime).format('DD/MM/YYYY'),
      }));
      onFilterProvided?.(data?.supplier?.code || '');
      onFilterAgent?.(data?.supplier?.type || '');
    } else {
      form.resetFields();
      setTimeState(prevState => ({
        ...prevState,
        start: '',
        end: '',
      }));
      setDateState(prevState => ({
        ...prevState,
        start: '',
        end: '',
      }));
    }
  };

  React.useEffect(() => {
    handleFillData(dataDetails);
  }, [dataDetails, isEdit]);

  useImperativeHandle(
    ref,
    () => {
      return {
        open: data => {
          setDataDetails(data);
          handleFillData(data);
          setOpen(true);
        },
      };
    },
    [isEdit]
  );

  const onSubmit = async (values: CampaignParamInterface) => {
    const courseIdSet = new Set();
    let isDuplicate = false;
    values?.productsMaxCommissionShare?.forEach(
      (item: ProductsMaxCommissionShare) => {
        if (courseIdSet.has(item.productId)) {
          isDuplicate = true;
          return;
        } else {
          courseIdSet.add(item.productId);
        }
      }
    );

    const params = {
      ...values,
      startTime: `${moment(values?.startTime).format(
        'YYYY-MM-DD'
      )} ${timeState?.start}`,
      endTime: `${moment(values?.endTime).format(
        'YYYY-MM-DD'
      )} ${timeState?.end}`,
      status: 0,
      productsMaxCommissionShare: values.productsMaxCommissionShare?.map(
        item => ({
          ...item,
          maxCommissionShare: parseFloat(
            item.maxCommissionShare.replace(/,/g, '')
          ),
        })
      ),
    };

    if (!isDuplicate) {
      if (isEdit) {
        updateCampaign(params, dataDetails?.id, onLoad);
      } else {
        if (
          moment(params?.startTime, 'YYYY-MM-DD HH:mm:ss').isSame(
            moment(params?.endTime, 'YYYY-MM-DD HH:mm:ss')
          )
        ) {
          message.error('Ngày bắt đầu và ngày kết thúc không hợp lệ !');
          return;
        } else {
          createCampaign(params, onLoad);
        }
      }
    } else {
      message.error('Sản phẩm bị trùng !');
    }
  };

  const onLoad = () => {
    setOpen(false);
  };

  const onclose = () => {
    setOpen(false);
  };

  const onchangeTime = (_: Dayjs | null, timeString: string, type: string) => {
    switch (type) {
      case TYPE.START:
        setTimeState(prevState => ({
          ...prevState,
          start: timeString,
        }));
        break;
      case TYPE.END:
        setTimeState(prevState => ({
          ...prevState,
          end: timeString,
        }));
        break;
      default:
        break;
    }
  };

  const productOptions = product?.map(item => ({
    value: item.id,
    label: item.name,
  }));

  return (
    <DrawerForm
      title={isEdit ? 'Chỉnh sửa chiến dịch' : 'Thêm mới chiến dịch'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      form={form}
      open={open}
      drawerProps={{
        destroyOnClose: true,
        onClose: onclose,
      }}
      submitTimeout={2000}
      onFinish={onSubmit}
      initialValues={{productsMaxCommissionShare: [{}]}}>
      <Form.Item
        label="Tên chiến dịch:"
        name="name"
        rules={[{required: true, message: 'Vui lòng nhập tên chiến dịch!'}]}
        style={{marginBottom: 15}}>
        <Input
          placeholder="Nhập tên chiến dịch..."
          className="w-[calc(100%)]"
        />
      </Form.Item>
      <Form.Item className="mb-0">
        <div className="flex">
          <Form.Item
            label="Ngày bắt đầu:"
            name="startTime"
            rules={[{required: true, message: 'Vui lòng chọn ngày bắt đầu!'}]}
            className="w-[calc(50%)] mb-15">
            <DatePicker
              format={dateFormat}
              placeholder={'Chọn ngày bắt đầu...'}
              className="w-[calc(100%)]"
              disabledDate={disabledDate}
              onChange={(value: string) => {
                setDateState(prev => ({
                  ...prev,
                  start: value,
                }));
              }}
            />
          </Form.Item>
          <Form.Item
            label="Thời gian bắt đầu:"
            rules={[
              {required: true, message: 'Vui lòng chọn thời gian bắt đầu!'},
            ]}
            className="w-[calc(50%)] ml-2">
            <TimePicker
              format={'HH:mm:ss'}
              className="w-[calc(100%)]"
              placeholder={'Chọn thời gian bắt đầu...'}
              value={
                timeState.start ? dayjs(timeState.start, 'HH:mm:ss') : null
              }
              onChange={(time, timeString: any) =>
                onchangeTime(time, timeString, TYPE.START)
              }
              disabledTime={() => {
                return {
                  disabledHours: () => getDisabledHours(dateState?.start),
                };
              }}
              disabled={isNullOrEmpty(dateState?.start)}
            />
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item className="mb-0">
        <div className="flex">
          <Form.Item
            label="Ngày kết thúc:"
            name="endTime"
            rules={[{required: true, message: 'Vui lòng chọn ngày kết thúc!'}]}
            className="w-[calc(50%)] mb-15">
            <DatePicker
              format={dateFormat}
              placeholder={'Chọn ngày kết thúc...'}
              className="w-[calc(100%)]"
              disabledDate={disabledDate}
              onChange={(value: string) => {
                setDateState(prev => ({
                  ...prev,
                  end: value,
                }));
              }}
            />
          </Form.Item>
          <Form.Item
            label="Thời gian kết thúc:"
            rules={[
              {required: true, message: 'Vui lòng chọn thời gian kết thúc!'},
            ]}
            className="w-[calc(50%)] ml-2">
            <TimePicker
              format={'HH:mm:ss'}
              className="w-[calc(100%)]"
              placeholder={'Chọn thời gian kết thúc...'}
              value={timeState.end ? dayjs(timeState.end, 'HH:mm:ss') : null}
              onChange={(time, timeString: any) =>
                onchangeTime(time, timeString, TYPE.END)
              }
              disabledTime={() => {
                return {
                  disabledHours: () => getDisabledHours(dateState?.end),
                };
              }}
              disabled={isNullOrEmpty(dateState?.end)}
            />
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item
        label="Nhà cung cấp:"
        name="supplierId"
        className="mb-4"
        rules={[{required: true, message: 'Vui lòng chọn nhà cung cấp !'}]}>
        <Select
          placeholder={'Chọn nhà cung cấp'}
          className="w-[calc(100%)]"
          allowClear
          options={suppliers.map(item => ({
            value: item.id,
            label: item.name,
            code: item.code,
            type: item.type,
          }))}
          disabled={isEdit}
          onChange={onChangeSelectProvided}
        />
      </Form.Item>

      <Typography className="mb-2">
        <span className="text-red-500">*</span> Chọn sản phẩm:
      </Typography>
      <Form.List name="productsMaxCommissionShare">
        {(fields, {add, remove}) => (
          <>
            {fields.map(({key, name, ...restField}, index) => (
              <div key={'item' + key} className="flex">
                <Form.Item
                  {...restField}
                  name={[name, 'productId']}
                  className="w-[calc(70%)]"
                  rules={[{required: true, message: 'Chọn sản phẩm'}]}>
                  <Select
                    allowClear
                    placeholder="Sản phẩm"
                    options={productOptions}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'maxCommissionShare']}
                  rules={[{required: true, message: 'Nhập hoa hồng'}]}
                  className="w-[calc(30%)] ml-2">
                  <Input
                    placeholder="Hoa hồng"
                    onChange={(e: {target: {value: string}}) => {
                      const updatedData = form.getFieldValue(
                        'productsMaxCommissionShare'
                      );
                      updatedData[index].maxCommissionShare = formatted(
                        e.target.value
                      );
                      form.setFieldsValue({
                        productsMaxCommissionShare: updatedData,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <DeleteOutlined
                    className="ml-2"
                    type="delete"
                    onClick={() => remove(name)}
                  />
                </Form.Item>
              </div>
            ))}
            <Form.Item>
              <Button
                type="default"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}>
                Thêm sản phẩm
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item
        name={'agents'}
        label="Chọn đại lý:"
        rules={[{required: true, message: 'Vui lòng chọn đại lý !'}]}>
        <Select
          placeholder="Chọn đại lý"
          mode="multiple"
          showSearch
          options={agents.map(item => ({
            value: item.id,
            label: item.name,
          }))}
          filterOption={(input, option) =>
            (option as {value: number; label: string}).label
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        />
      </Form.Item>

      <Form.Item label="Ghi chú:" name="note" style={{marginBottom: 15}}>
        <Input.TextArea
          placeholder="Nhập ghi chú..."
          className="w-[calc(100%)]"
        />
      </Form.Item>
    </DrawerForm>
  );
};

export const CreateModal = forwardRef(ModalCreate);
