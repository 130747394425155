import {isRoleCTV, isUserHaveOneOfPermissions} from '@/utils';
import {ROUTERS} from '@/utils/constants';
import {LOCAL_STORAGE_KEY, ROLES} from '@/utils/enum';
import {
  AppstoreOutlined,
  // FileSearchOutlined,
  HomeOutlined,
  LayoutOutlined,
  OrderedListOutlined,
  ProfileOutlined,
  SolutionOutlined,
  TableOutlined,
  TeamOutlined,
  CreditCardOutlined,
  FileOutlined,
  FundProjectionScreenOutlined,
  ProjectOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import {ReactNode} from 'react';

const role = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE);

export type MenuItemProps = {
  path?: string;
  name: string;
  icon: ReactNode;
  children?: {
    path: string;
    name: string;
  }[];
};

const routes = [
  {
    path: ROUTERS.Dashboard.path,
    name: 'Trang chủ',
    icon: isUserHaveOneOfPermissions([
      ROLES.ADMIN,
      ROLES.AM_LEADER,
      ROLES.AM,
    ]) ? (
      <FundProjectionScreenOutlined className="text-lg" />
    ) : (
      <HomeOutlined className="text-lg" />
    ),
  },
  // {
  //   path: ROUTERS.Search.path,
  //   name: 'Tra cứu',
  //   icon: <FileSearchOutlined className="text-lg" />,
  //   hidden: isUserHaveOneOfPermissions([ROLES.ACT]),
  // },
  {
    path: ROUTERS.Accounts.path,
    name: 'Quản lý tài khoản',
    icon: <SolutionOutlined className="text-lg" />,
    hidden: !isUserHaveOneOfPermissions([ROLES.ADMIN, ROLES.AM_LEADER]),
  },
  {
    path: ROUTERS.Collaborators.path,
    name: isUserHaveOneOfPermissions([ROLES.ADMIN, ROLES.AM_LEADER, ROLES.AM])
      ? 'Quản lý đối tác'
      : 'Quản lý CTV',
    icon: <TeamOutlined className="text-lg" />,
    hidden: !isUserHaveOneOfPermissions([
      ROLES.ADMIN,
      ROLES.AM_LEADER,
      ROLES.AM,
      ROLES.AGENT,
    ]),
    children: [
      isUserHaveOneOfPermissions([ROLES.ADMIN]) && {
        path: ROUTERS.Organizations.path,
        name: 'Danh sách tổ chức',
      },
      {
        path: ROUTERS.Collaborators.path,
        name: isUserHaveOneOfPermissions([
          ROLES.ADMIN,
          ROLES.AM_LEADER,
          ROLES.AM,
        ])
          ? 'Danh sách đại lý'
          : 'Danh sách CTV',
      },
    ],
  },
  {
    path: ROUTERS.Notification.path,
    name: 'Quản lý thông báo',
    icon: <NotificationOutlined className="text-lg" />,
    hidden: !isUserHaveOneOfPermissions([ROLES.ADMIN]),
  },
  {
    path: ROUTERS.Products.path,
    name: isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR])
      ? 'Danh sách sản phẩm'
      : 'Quản lý sản phẩm',
    icon: <AppstoreOutlined className="text-lg" />,
    hidden: isUserHaveOneOfPermissions([ROLES.ACT]),
  },
  {
    path: ROUTERS.Campaigns.path,
    name:
      role === ROLES.AGENT || role === ROLES.COLLABORATOR
        ? 'Danh sách chiến dịch'
        : 'Quản lý chiến dịch',
    icon: <TableOutlined className="text-lg" />,
    hidden: isUserHaveOneOfPermissions([ROLES.ACT]),
  },
  {
    path: ROUTERS.Posts.path,
    name: 'CMS',
    icon: <ProjectOutlined className="text-lg" />,
    hidden: !isUserHaveOneOfPermissions([ROLES.ADMIN]),
  },
  {
    path: ROUTERS.Documents.path,
    name: 'Tài liệu bán hàng',
    icon: <LayoutOutlined className="text-lg" />,
    hidden: isUserHaveOneOfPermissions([ROLES.ACT]),
  },
  {
    path: ROUTERS.Orders.path,
    name: isRoleCTV() ? 'Danh sách đơn hàng' : 'Quản lý đơn hàng',
    icon: <OrderedListOutlined className="text-lg" />,
  },
  {
    path: isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR])
      ? null
      : ROUTERS.Payments.path,
    name: 'Quản lý thanh toán',
    icon: <CreditCardOutlined className="text-lg" />,
    children: isUserHaveOneOfPermissions([ROLES.AGENT, ROLES.COLLABORATOR])
      ? [
          {
            path: ROUTERS.Payments.path,
            name: 'Yêu cầu thanh toán',
          },
          {
            path: ROUTERS.BalanceHistory.path,
            name: 'Biến động số dư',
          },
        ]
      : null,
    hidden: isUserHaveOneOfPermissions([ROLES.AM_LEADER, ROLES.AM]),
  },
  {
    path: ROUTERS.ReportCampaign.path,
    name: 'Thống kê báo cáo',
    icon: <ProfileOutlined className="text-lg" />,
    hidden: !isUserHaveOneOfPermissions([
      ROLES.ADMIN,
      ROLES.AM_LEADER,
      ROLES.AM,
      ROLES.AGENT,
    ]),
  },
  {
    path: ROUTERS.ImportedMOFile.path,
    name: 'Import / Export Files',
    icon: <FileOutlined className="text-lg" />,
    hidden: !isUserHaveOneOfPermissions([ROLES.ADMIN, ROLES.AM_LEADER]),
  },
].filter(item => !item?.hidden);

export default {
  route: {
    path: '/',
    routes: routes,
  },
};
