import {AxiosResponse} from 'axios';
import {NetWork} from '..';
import {API_URL_AGENT, API_URL_REPORT} from '../apiUrl';
import {getRequestUrl} from '../utils';

const totalRevenue = (
  params?: ReportParams
): Promise<AxiosResponse<TotalRevenueResponse>> => {
  const url = getRequestUrl(API_URL_REPORT.report, {
    parentId: API_URL_REPORT.revenue,
    partial: API_URL_REPORT.total,
  });
  return NetWork.get(url, params);
};

const agentsRevenue = (
  params?: ReportTableParams
): Promise<AxiosResponse<AgentsRevenueResponse>> => {
  const url = getRequestUrl(API_URL_REPORT.report, {
    parentId: API_URL_REPORT.revenue,
    partial: API_URL_REPORT.agents,
  });
  return NetWork.get(url, params);
};

const orderCount = (
  params?: ReportParams
): Promise<AxiosResponse<OrderCountResponse>> => {
  const url = getRequestUrl(API_URL_REPORT.report, {
    parentId: API_URL_REPORT.order,
    partial: API_URL_REPORT.count,
  });
  return NetWork.get(url, params);
};

const orderAgents = (
  params?: ReportTableParams
): Promise<AxiosResponse<AgentsOrderResponse>> => {
  const url = getRequestUrl(API_URL_REPORT.report, {
    parentId: API_URL_REPORT.order,
    partial: API_URL_REPORT.agents,
  });
  return NetWork.get(url, params);
};

const reportCampaign = (
  params?: ReportTableParams
): Promise<AxiosResponse<ReportCampaignResponse>> => {
  const url = getRequestUrl(API_URL_REPORT.report, {
    parentId: API_URL_REPORT.campaigns,
    partial: API_URL_REPORT.products,
    subId: API_URL_AGENT.agent,
  });
  return NetWork.get(url, params);
};

const reportCampaignAgent = (
  params?: ReportTableParams
): Promise<AxiosResponse<ReportCampaignResponse>> => {
  const url = getRequestUrl(API_URL_REPORT.reportCampaignAgent);
  return NetWork.get(url, params);
};

const reportCampaignDetails = (
  collaboratorId: number
): Promise<AxiosResponse<ReportCampaignResponse>> => {
  const url = getRequestUrl(API_URL_REPORT.report, {
    parentId: API_URL_REPORT.campaigns,
    partial: API_URL_REPORT.products,
    subId: API_URL_REPORT.collaborator,
    action: collaboratorId,
  });
  return NetWork.get(url);
};

const revenueService = {
  totalRevenue,
  agentsRevenue,
  orderCount,
  orderAgents,
  reportCampaign,
  reportCampaignAgent,
  reportCampaignDetails,
};

export default revenueService;
