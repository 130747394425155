import {BellOutlined} from '@ant-design/icons';
import {FC} from 'react';

type Props = {
  item: NotificationItem;
  onClick: () => void;
};

const ItemNotification: FC<Props> = ({item, onClick}) => {
  return (
    <div
      className={[
        'px-2 py-3 mt-2 rounded-md cursor-pointer',
        item?.isRead ? ' ' : 'bg-slate-50',
      ].join(' ')}
      onClick={onClick}>
      <div className="flex">
        <BellOutlined className="mr-4" />
        <div>
          <div className="text-bold">{item.title}</div>
          <div className="line-clamp-2 text-neutral-400">{item.content}</div>
        </div>
      </div>
    </div>
  );
};

export default ItemNotification;
