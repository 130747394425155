import {ColumnsType} from 'antd/es/table';
import moment from 'moment';

const getColumnsConfig = () => {
  const columnsActive: ColumnsType<ActivePackage> = [
    {
      title: 'Mã gói',
      dataIndex: 'packageCode',
      key: 'packageCode',
      align: 'center',
    },
    {
      title: 'Thời gian đăng kí',
      dataIndex: 'activeDate',
      key: 'activeDate',
      align: 'center',
      render: (_, record) => {
        return moment(record.activeDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Thời gian hết hạn',
      dataIndex: 'expireDate',
      key: 'expireDate',
      align: 'center',
      render: (_, record) => {
        return moment(record.expireDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
  ];

  const columnsSuggest: ColumnsType<SuggestedPackages> = [
    {
      title: 'Loại',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (_, record) => {
        return <div>{record?.name || '--'}</div>;
      },
    },
    {
      title: 'Danh sách gói cước',
      dataIndex: 'package',
      key: 'package',
      align: 'center',
      render: (_, record) => {
        return <div className="font-semibold">{record?.package || '--'}</div>;
      },
    },
  ];

  return {columnsActive, columnsSuggest};
};
export default getColumnsConfig;
